import React, { useRef, useState } from 'react';
import './Slider1.css'
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';



// import required modules
import { Pagination } from 'swiper/modules';

export default function Slider1() {
    return (
        <>
            <section className='slider1'>
            <div className='container'>


                <Swiper
                    slidesPerView={5}
                    spaceBetween={30}
                 
                    pagination={{
                        clickable: true,
                    }}
                    modules={[Pagination]}
                    className="mySwiper"
                >


                   
                    <SwiperSlide><div class="item swiper-slide swiper-slide-duplicate" data-swiper-slide-index="10" style={{ width: "234px" }}>
                                <img loading="lazy" src="https://cdn.acodez.in/wp-content/themes/acodez-theme/images/clients/switzerland.png" alt="" />
                            </div>
                            
                    </SwiperSlide>


                    <SwiperSlide><div class="item swiper-slide swiper-slide-duplicate" data-swiper-slide-index="11" style={{ width: "234px" }}>
                                    <img loading="lazy" src="https://cdn.acodez.in/wp-content/themes/acodez-theme/images/clients/indomie.png" alt="" />
                                </div>
                                
                    </SwiperSlide>
                    <SwiperSlide>
                           <div class="item swiper-slide swiper-slide-duplicate swiper-slide-prev" data-swiper-slide-index="12" style={{ width: "234px" }}>
                                    <img loading="lazy" src="https://cdn.acodez.in/wp-content/themes/acodez-theme/images/clients/amrita.png" alt="" />
                                </div>
                                
                    </SwiperSlide>
                    <SwiperSlide>  
                    <div class="item swiper-slide swiper-slide-duplicate swiper-slide-active" data-swiper-slide-index="13" style={{ width: "234px" }}>
                                    <img loading="lazy" src="https://cdn.acodez.in/wp-content/themes/acodez-theme/images/clients/quantifi.png" alt="" />
                                </div>
                                
                    </SwiperSlide>
                    <SwiperSlide>
                    <div class="item swiper-slide swiper-slide-duplicate swiper-slide-next" data-swiper-slide-index="14" style={{ width: "234px" }}>
                                    <img loading="lazy" src="https://cdn.acodez.in/wp-content/themes/acodez-theme/images/clients/datamark.png" alt="" />
                                </div>
                                
                    </SwiperSlide>
                    <SwiperSlide>
                    <div class="item swiper-slide" data-swiper-slide-index="0" style={{ width: "234px" }}>
                                    <img loading="lazy" src="https://cdn.acodez.in/wp-content/themes/acodez-theme/images/clients/panasonic.png" alt="" />
                                </div>
                                
                    </SwiperSlide>
                    <SwiperSlide>
                    <div class="item swiper-slide" data-swiper-slide-index="1" style={{ width: "234px" }}>
                                    <img loading="lazy" src="https://cdn.acodez.in/wp-content/themes/acodez-theme/images/clients/anchor.png" alt="" />
                                </div>
                                
                    </SwiperSlide>
                    {/* <SwiperSlide>Slide 4</SwiperSlide>
                    <SwiperSlide>Slide 5</SwiperSlide>
                    <SwiperSlide>Slide 6</SwiperSlide>
                    <SwiperSlide>Slide 7</SwiperSlide>
                    <SwiperSlide>Slide 8</SwiperSlide>
                    <SwiperSlide>Slide 9</SwiperSlide> */}
                </Swiper>
                </div>
            </section>
        </>
    );
}

