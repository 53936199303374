import React from 'react'
import './Work.css'
import Header from '../../header/Header'
import Footer from '../../Footer1/Footer'
import Footer2 from '../../footer2/Footer2'
const Work = () => {
    return (
        <div className='work-page'>
            <Header />
            <section class="inner-page">
                <div class="inner-banner work-img">
                    <div class="img "></div>
                    <img src="https://cdn.acodez.in/wp-content/themes/acodez-theme/images/works-banner.jpg" alt="" />
                    <div class="caption">
                        <div class="container">
                            <div class="row">
                                <div class="col-md-6 col-md-offset-6">
                                    <h2>Works</h2>
                                    <p>Acodez is a renowned <a href="">web design company in India</a> with more than 16 international awards to its credit. We have been designing, developing, branding, optimizing, and creating apps and web application development for a number of clients belonging to various industry dimensions ranging from MNCs to one man start-ups.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='container'>
<h3 class="main-title-h3">We let our Portfolio do the talk!</h3>
                
                <div className='work-page-1'>

                    <div class="item  websites" >
                        <div class="outer">
                            <figure class="imghvr-hinge-up">


                                <img src="https://cdn.acodez.in/wp-content/uploads/2017/09/anchor.jpg" alt="" />

                                {/* <figcaption>
                                <h3>TrustED</h3>



                                <a href="https://www.trusteducation.io/" rel="nofollow" class="link-1 ion-ios-search-strong" target="_blank" title="Visit Website"></a>


                            </figcaption> */}
                            </figure>
                            <div class="trusted">
                                <h4>Anchor Electricals</h4>
                                <p> India</p>
                            </div>
                        </div>
                    </div>

                    <div class="item  websites" >
                        <div class="outer">
                            <figure class="imghvr-hinge-up">


                                <img src="https://cdn.acodez.in/wp-content/uploads/2017/09/facespa-1.jpg" alt="" />

                                {/* <figcaption>
                                <h3>TrustED</h3>



                                <a href="https://www.trusteducation.io/" rel="nofollow" class="link-1 ion-ios-search-strong" target="_blank" title="Visit Website"></a>


                            </figcaption> */}
                            </figure>
                            <div class="trusted">
                                <h4>Facespa</h4>
                                <p>Australia</p>
                            </div>
                        </div>
                    </div>
                    <div class="item  websites" >
                        <div class="outer">
                            <figure class="imghvr-hinge-up">


                                <img src="https://cdn.acodez.in/wp-content/uploads/2017/09/praudfoods.jpg" alt="" />

                                {/* <figcaption>
                                <h3>TrustED</h3>



                                <a href="https://www.trusteducation.io/" rel="nofollow" class="link-1 ion-ios-search-strong" target="_blank" title="Visit Website"></a>


                            </figcaption> */}
                            </figure>
                            <div class="trusted">
                                <h4>Praud Foods</h4>
                                <p> India	</p>
                            </div>
                        </div>
                    </div>
                    <div class="item  websites" >
                        <div class="outer">
                            <figure class="imghvr-hinge-up">


                                <img src="https://cdn.acodez.in/wp-content/uploads/2022/10/Amrita-Ahead.jpg" alt="" />

                                {/* <figcaption>
                                <h3>TrustED</h3>



                                <a href="https://www.trusteducation.io/" rel="nofollow" class="link-1 ion-ios-search-strong" target="_blank" title="Visit Website"></a>


                            </figcaption> */}
                            </figure>
                            <div class="trusted">
                                <h4>Amrita University</h4>
                                <p>Amrita Vishwa Vidyapeetham, India</p>
                            </div>
                        </div>
                    </div>


                </div>
                </div>


            </section>
            <Footer />
			<Footer2 />
        </div>
    )
}

export default Work
