import React, { useEffect, useState } from 'react'
import './Hero.css'

const Hero = () => {
  const [texts, setTexts] = useState({ text1: '', text2: '', text3: '' });

  useEffect(() => {
    const master = () => {
      setTimeout(() => {
        setTexts({
          text1: `<span></span>We Develop `,
          text2: ` <span></span>Scalable`,
          text3: `<span></span>Mobile Apps`,
        });
        planning();
      }, 0); // Initial delay, you can adjust it if needed
    };

    const planning = () => {
      setTimeout(() => {
        setTexts({
          text1: `<span></span>We Create`,
          text2: `<span></span>Stunning `,
          text3: `<span></span>Websites`,
        });
        planning2();
      }, 1200);
    };

    const planning2 = () => {
      setTimeout(() => {
        setTexts({
          text1: `<span></span>Top Notch.`,
          text2: `<span></span>Award Winning.`,
          text3: `<span></span>Affordable.`,
        });
        master();
      }, 2400);
    };

    master(); // Start the cycle

    return () => {
      // Cleanup all timeouts on component unmount
      clearTimeout();
    };
  }, []);




  return (
    <section className='hero'>

      <div className='hero-child'>

      <div className='text1'>
      <div className='text-child'>
      <span></span>
      <p id='hero-text1' dangerouslySetInnerHTML={{ __html: texts.text1 }} />
     
       
      <p id='hero-text2' dangerouslySetInnerHTML={{ __html: texts.text2 }}/>
      <p id='hero-text3' dangerouslySetInnerHTML={{ __html: texts.text3 }}/>
      </div>
    
     


      </div>

      </div>
    </section>
  )
}

export default Hero
