import React from 'react'
import './Achievement.css'
import { Swiper, SwiperSlide } from 'swiper/react';
import img1 from '../../img/location-icon.webp'
import img2 from '../../img/aco-icon-1.webp'
import img3 from '../../img/projects-icon.webp'
import img4 from '../../img/awards-icon.webp'
import img5 from '../../img/location-icon.webp'

import ScrollAnimation from 'react-animate-on-scroll';
import "animate.css/animate.compat.css"
import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination } from 'swiper/modules';
const Achievement = () => {
    return (
        <ScrollAnimation animateIn="fadeInUp" >
        <section>
            <div className="achievement-main">
                <div className="container">
                    <div className="achievement-outer achievement-slide swiper-container-horizontal">

                        <Swiper
                            rewind={true}
                            slidesPerView={5}
                            spaceBetween={30}

                            pagination={{
                                clickable: true,
                            }}
                            modules={[Pagination]}
                            classNameName="mySwiper"
                        >

                            <div className=" achievement swiper-wrapper" >

                                <SwiperSlide>
                                    <div className="swiper-slide col-4 swiper-slide-duplicate swiper-slide-prev" data-swiper-slide-index="3" style={{ width: "292.5px" }}>
                                        <a href="#">
                                            <div className="counts fadeInUp animated">
                                                <i className="ion-ios-location"></i>
                                                <h3>6</h3>
                                                Office <br /> Locations
                                            </div>
                                            <div className="icon">
                                                <img loading="lazy" src={img1} alt="" />
                                            </div>
                                        </a>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="swiper-slide col-1 swiper-slide-duplicate swiper-slide-duplicate-active" data-swiper-slide-index="0" style={{ width: "292.5px" }}>


                                        <a href="#">
                                            <div className="counts fadeInUp animated">
                                                <i className="ion-earth"></i>
                                                <h3>80+</h3>
                                                Countries <br /> Served
                                            </div>
                                            <div className="icon">
                                                <img loading="lazy" src={img2} alt="" />
                                            </div>
                                        </a>

                                    </div>
                                </SwiperSlide>

                                <SwiperSlide>
                                    <div className="swiper-slide col-2 swiper-slide-duplicate swiper-slide-duplicate-next" data-swiper-slide-index="1" style={{ width: "292.5px" }}>
                                        <a href="#">
                                            <div className="counts fadeInUp animated">
                                                <i className="ion-clipboard"></i>
                                                <h3>1000+</h3>
                                                Projects<br /> Completed
                                            </div>
                                            <div className="icon">
                                                <img loading="lazy" src={img3} alt="" />
                                            </div>
                                        </a>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="swiper-slide col-3 swiper-slide-duplicate" data-swiper-slide-index="2" style={{ width: "292.5px" }}>
                                        <a href="#">
                                            <div className="counts fadeInUp animated">
                                                <i className="ion-trophy"></i>
                                                <h3>16</h3>
                                                International<br /> Awards
                                            </div>
                                            <div className="icon">
                                                <img loading="lazy" src={img4} alt="" />
                                            </div>
                                        </a>
                                    </div>
                                </SwiperSlide>


                                <SwiperSlide>
                                    <div className="swiper-slide col-1 swiper-slide-active" data-swiper-slide-index="0" style={{ width: "292.5px" }}>
                                        <a href="#">
                                            <div className="counts fadeInUp animated">
                                                <i className="ion-earth"></i>
                                                <h3>80+</h3>
                                                Countries <br /> Served
                                            </div>
                                            <div className="icon">
                                                <img loading="lazy" src={img2} alt="" />
                                            </div>
                                        </a>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="swiper-slide col-2 swiper-slide-next" data-swiper-slide-index="1" style={{ width: "292.5px" }}>
                                        <a href="#">
                                            <div className="counts fadeInUp animated">
                                                <i className="ion-clipboard"></i>
                                                <h3>1000+</h3>
                                                Projects<br /> Completed
                                            </div>
                                            <div className="icon">
                                                <img loading="lazy" src={img3} alt="" />
                                            </div>
                                        </a>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="swiper-slide col-3" data-swiper-slide-index="2" style={{ width: "292.5px" }}>
                                        <a href="#">
                                            <div className="counts fadeInUp animated">
                                                <i className="ion-trophy"></i>
                                                <h3>16</h3>
                                                International<br /> Awards
                                            </div>
                                            <div className="icon">
                                                <img loading="lazy" src={img4} alt="" />
                                            </div>
                                        </a>
                                    </div>
                                </SwiperSlide>

                                <SwiperSlide>
                                    <div className="swiper-slide col-4 swiper-slide-duplicate-prev" data-swiper-slide-index="3" style={{ width: "292.5px" }}>
                                        <a href="#">
                                            <div className="counts fadeInUp animated">
                                                <i className="ion-ios-location"></i>
                                                <h3>6</h3>
                                                Office <br /> Locations
                                            </div>
                                            <div className="icon">
                                                <img loading="lazy" src={img1} alt="" />
                                            </div>
                                        </a>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="swiper-slide col-1 swiper-slide-duplicate swiper-slide-duplicate-active" data-swiper-slide-index="0" style={{ width: "292.5px" }}>
                                        <a href="#">
                                            <div className="counts fadeInUp animated">
                                                <i className="ion-earth"></i>
                                                <h3>80+</h3>
                                                Countries <br /> Served
                                            </div>
                                            <div className="icon">
                                                <img loading="lazy" src={img2} alt="" />
                                            </div>
                                        </a>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="swiper-slide col-2 swiper-slide-duplicate swiper-slide-duplicate-next" data-swiper-slide-index="1" style={{ width: "292.5px" }}>
                                        <a href="#">
                                            <div className="counts fadeInUp animated">
                                                <i className="ion-clipboard"></i>
                                                <h3>1000+</h3>
                                                Projects<br /> Completed
                                            </div>
                                            <div className="icon">
                                                <img loading="lazy" src={img3} alt="" />
                                            </div>
                                        </a>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="swiper-slide col-3 swiper-slide-duplicate" data-swiper-slide-index="2" style={{ width: "292.5px" }}>
                                        <a href="#">
                                            <div className="counts fadeInUp animated">
                                                <i className="ion-trophy"></i>
                                                <h3>16</h3>
                                                International<br /> Awards
                                            </div>
                                            <div className="icon">
                                                <img loading="lazy" src={img4} alt="" />
                                            </div>
                                        </a>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="swiper-slide col-4 swiper-slide-duplicate" data-swiper-slide-index="3" style={{ width: "292.5px" }}>
                                        <a href="#">
                                            <div className="counts fadeInUp animated">
                                                <i className="ion-ios-location"></i>
                                                <h3>6</h3>
                                                Office <br /> Locations
                                            </div>
                                            <div className="icon">
                                                <img loading="lazy" src={img1} alt="" />
                                            </div>
                                        </a>
                                    </div>
                                </SwiperSlide>
                            </div>

                        </Swiper>


                        <div className="swiper-button-next"></div>
                        <div className="swiper-button-prev swiper-button-disabled"></div>
                    </div>
                    {/* <!-- achievement --> */}

                    <div className="testimonial tsml-slider swiper-container-horizontal">
                    <Swiper
                                rewind={true}
                                
                                navigation={true}

                                pagination={{
                                    clickable: true,
                                }}
                                modules={[Pagination]}
                                classNameName="mySwiper"
                            >
                        <div className="slider swiper-wrapper man">

                           
                                <SwiperSlide>
                                    <div className="swiper-slide swiper-slide-active" style={{ width: " 810px" }}>
                                        <div className="pic">
                                            <span>
                                                <img loading="lazy" src="https://cdn.acodez.in/wp-content/themes/acodez-theme/images/hiroyasu-tsuchiya.jpg" alt="" />
                                            </span>

                                        </div>
                                        <p>Thank you very much for creating a fantastic corporate website for us. I deeply appreciate
                                            your efforts and guidance, without which, this wouldn't have been possible.</p>
                                        <h4>Hiroyasu Tsuchiya </h4>
                                        <span>Corporate Branding Division, Anchor by Panasonic</span>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                <div className="swiper-slide swiper-slide-next" style={{ width: "810px" }}>
                                    <div className="pic">
                                        <span>
                                            <img loading="lazy" src="https://cdn.acodez.in/wp-content/themes/acodez-theme/images/tsm-pic.jpg" alt="" />
                                        </span>

                                    </div>
                                    <p>I was really convinced with the previous works they've done and hence I contacted them,
                                        eventhough I'm from Paris and they're in India...And I really liked the design they've done
                                        for me too... I strongly recommend NJS Solutions to anyone who's looking for quality stuffs.</p>
                                    <h4>Simo Bennis </h4>
                                    <span>Seduction Naturelle PNL, France</span>
                                </div>
                                </SwiperSlide>
                            

                        </div>
                        </Swiper>
                       
                    </div>
                    {/* <!-- end testimonial --> */}
                </div>
            </div>
        </section>
        </ScrollAnimation>
    )
}

export default Achievement
