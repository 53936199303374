import React from 'react'
import './Won.css'
import wonImg from '../../img/awards-gif.gif'
import ScrollAnimation from 'react-animate-on-scroll';
import "animate.css/animate.compat.css"
const Won = () => {
  return (
    <section className='won'>
      <div className='container'>
     

      <div className='won-box1'>
        <div className='won-box1-child1'>
        <h2>We ‘ve won...</h2>
        <p>Multiple international awards, every year, since 2012! Apart from the 16 international awards
            that we've won, we're also a Google Certified Partner and Bing Ads Accredited Professional
            Company. We've also been featured and mentioned in many leading tech magazines and portals
            for our various contributions.</p>

            <a href='' className='button-1 color-b'>View more </a>
            <div className='won-img'>
                <img src={wonImg}></img>
            </div>
        </div>

        <div className='won-box1-child1'>
        <div class="won-box1-child2">
                        <div class="aw-images-inner">
                           
                           
                            {/* <!-- Awards --> */}
                            <ScrollAnimation animateIn="fadeInLeft" >
                            <div class="box box-2 ">
                                <h4><span>16</span>Awards</h4>
                                <ul class="list-02">
                                    <li class="clearfix">
                                        <a href="https://acodez.in/awards/" className='won-flex'>
                                            <div class="col-xs-3 pic">
                                                <img loading="lazy" src="https://acodez.in/wp-content/themes/acodez-theme/images/new/awards.svg" alt="acodez awards"/>
                                            </div>
                                            <div class="col-xs-9 cont-area">
                                                <h6>Global Trends</h6>
                                                <p>Triumph Winner<br/>
                                                    2018, USA </p>
                                            </div>
                                        </a>
                                    </li>
                                    <li class="clearfix">
                                        <a href="https://acodez.in/awards/" className='won-flex'>
                                            <div class="col-xs-3 pic">
                                                <img loading="lazy" src="https://acodez.in/wp-content/themes/acodez-theme/images/new/awards.svg" alt="acodez awards"/>
                                            </div>
                                            <div class="col-xs-9 cont-area">
                                                <h6>DotComm Awards</h6>
                                                <p>Platinum<br/>
                                                    2017, USA </p>
                                            </div>
                                        </a>
                                    </li>
                                    <li class="clearfix">
                                        <a href="https://acodez.in/awards/" className='won-flex'>
                                            <div class="col-xs-3 pic">
                                                <img loading="lazy" src="https://acodez.in/wp-content/themes/acodez-theme/images/new/awards.svg" alt="acodez awards"/>
                                            </div>
                                            <div class="col-xs-9 cont-area">
                                                <h6>Muse Awards</h6>
                                                <p>Gold Winner<br/>
                                                    2017, USA</p>
                                            </div>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            </ScrollAnimation>
                            <div class="accolades-heading">Accolades</div>
                           

                            <div class="new-accolades">
                                <ul>
                                    <li class="fadeInUp animated">
                                        <a href="https://acodez.in/awards/">
                                            <img loading="lazy" src="https://cdn.acodez.in/wp-content/themes/acodez-theme/images/new/mashable.png" alt=""/>
                                        </a>
                                    </li>
                                    <li class="fadeInUp animated">
                                        <a href="https://acodez.in/awards/">
                                            <img loading="lazy" src="https://cdn.acodez.in/wp-content/themes/acodez-theme/images/new/techradar.png" alt=""/>
                                        </a>
                                    </li>
                                    <li class="fadeInUp animated">
                                        <a href="https://acodez.in/awards/">
                                            <img loading="lazy" src="https://cdn.acodez.in/wp-content/themes/acodez-theme/images/new/smashing.png" alt=""/>
                                        </a>
                                    </li>
                                    <li class="fadeInUp animated">
                                        <a href="https://acodez.in/awards/">
                                            <img loading="lazy" src="https://cdn.acodez.in/wp-content/themes/acodez-theme/images/new/siliconindia.png" alt=""/>
                                        </a>
                                    </li>
                                    <li class="fadeInUp animated">
                                        <a href="https://acodez.in/awards/">
                                            <img loading="lazy" src="https://cdn.acodez.in/wp-content/themes/acodez-theme/images/new/bing.png" alt=""/>
                                        </a>
                                    </li>
                                    <li class="fadeInUp animated">
                                        <a href="https://acodez.in/awards/">
                                            <img loading="lazy" src="https://cdn.acodez.in/wp-content/themes/acodez-theme/images/new/google.png" alt=""/>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>

        </div>
      </div>
      </div>
    </section>
  )
}

export default Won
