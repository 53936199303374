import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import FrontPage from './component/frontPage/FrontPage';
import Services from './component/page/services/Services';
import Products from './component/page/products/Products';
import Blog from './component/page/blog/Blog';
import Contact from './component/page/contact/Contact';
import Work from './component/page/work/Work';
import Technologies from './component/page/technologies/Technologies';
import About from './component/page/aboutPage/About';
import './component/hero/app'
import { useEffect } from 'react';
function App() {



    
  return (
 
    <>
 <BrowserRouter>

<Routes>

<Route exact path='/' element={<FrontPage/>}></Route>
<Route exact path='/services' element={<Services/>}></Route>
<Route exact path='/products' element={<Products/>}></Route>
<Route exact path='/blog' element={<Blog/>}></Route>
<Route exact path='/contact' element={<Contact/>}></Route>
<Route exact path='/work' element={<Work/>}></Route>
<Route exact path='/technologies' element={<Technologies/>}></Route>
<Route exact path='/about' element={<About/>}></Route>
</Routes>
</BrowserRouter>
    </>
  );
}

export default App;
