import React from 'react'
import './Footer.css'
import {Link } from "react-router-dom";
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';



// import required modules
import { Pagination } from 'swiper/modules';
const Footer2 = () => {
    return (
        <section>
            <div class="footer-sec-2">
                <div class="container">
                    <div class="location-outer">
                        <div class="addreess-section location-slide swiper-container-horizontal">
                            <ul class="swiper-wrapper clearfix">
                            <Swiper
                    
                    breakpoints={{
                                576: {
                                width: 576,
                                slidesPerView: 2,
                                },
                                768: {
                                width: 768,
                                slidesPerView: 4,
                                },
                            }}
                            
                    spaceBetween={100}
                    pagination={{
                        clickable: true,
                    }}
                    
                    modules={[Pagination]}
                    className="mySwiper"
                >

<SwiperSlide>

                                <li class="swiper-slide swiper-slide-active" style={{ width: " 292.5px" }}>
                                    <h4>Delhi NCR</h4>
                                    <p>
                                        1101 - 11th Floor<br />JMD Megapolis, Sector-48<br /> Gurgaon, Delhi NCR - India</p>
                                    <a href="" target="_blank" class="map">Google Maps</a>
                                </li>
                                </SwiperSlide>
                                <SwiperSlide>
                                <li class="swiper-slide swiper-slide-next" style={{ width: " 292.5px" }}>
                                    <h4>Mumbai </h4>
                                    <p>
                                        1st floor, Urmi Corporate Park<br />
                                        Solaris (D) Opp. L&amp;T Gate No.6<br />
                                        Powai, Mumbai- 400072</p>


                                    <a href="" target="_blank" class="map">Google Maps</a>
                                </li>
                                </SwiperSlide>
                                <SwiperSlide>
                                <li class="swiper-slide" style={{ width: " 292.5px" }}>
                                    <h4>Bangalore</h4>
                                    <p>
                                        #12, 100 Feet Road <br />
                                        Banaswadi,<br />
                                        Bangalore 5600432</p>
                                    <a href="" target="_blank" class="map">Google Maps</a>
                                </li>
                                </SwiperSlide>
                                <SwiperSlide>
                                <li class="swiper-slide" style={{ width: " 292.5px" }}>
                                    <h4>Calicut (SEZ Unit)</h4>
                                    <p>
                                        UL CyberPark  (SEZ) <br />
                                        Nellikode (PO)<br /> Kerala, India -673 016</p>
                                    <a href="" target="_blank" class="map">Google Maps</a>
                                </li>
                                </SwiperSlide>
                                <SwiperSlide>
                                <li class="swiper-slide" style={{ width: " 292.5px" }}>
                                    <h4>Calicut </h4>
                                    <p>
                                        Westhill, Kozhikode<br />
                                        Kerala - 673005<br />
                                        India</p>
                                    <a href="" target="_blank" class="map">Google Maps</a>
                                </li>
                                </SwiperSlide>
                                <SwiperSlide>
                                <li class="swiper-slide" style={{ width: " 292.5px" }}>
                                    <h4>Kochi </h4>
                                    <p>
                                        40/383, 5th Floor, <br />
                                        Jos Annex, Jose Junction,<br />
                                        M.G.Road, Kochi-682016
                                    </p>
                                    <a href="" target="_blank" class="map">Google Maps</a>
                                </li>
</SwiperSlide>
</Swiper>
                            </ul>
                        </div>
                        <div class="swiper-button-next"></div>
                        <div class="swiper-button-prev swiper-button-disabled"></div>
                    </div>
                    <div class="footer-links row">
                        <div class="col-sm-6 col-lg-2 links-02">
                            <h4>Navigation</h4>
                            <ul class="list-f02">
                                <li><Link to="/">Home</Link></li>
                                <li><Link to="/about">About</Link></li>
                                <li><Link to="/technologies">Technologies</Link></li>
                                <li><Link to="/work">Works</Link></li>
                                <li><Link to="/services">Services</Link></li>
                                <li><Link to="/product">product</Link></li>
                                <li><Link to="/blog">Blog</Link></li>
                                <li><Link to="/contact">Contact</Link></li>
                            </ul>
                        </div>
                        <div class="col-sm-6 col-lg-3 links-02">
                            <h4>Services</h4>
                            <ul class="list-f02">
                                <li><a href="" class="">Web Development</a></li>
                                <li><a href="">UX Design</a></li>
                                <li><a href="hnt/">Mobile Apps</a></li>
                                <li><a href="">Digital Marketing</a></li>
                                <li><a href="" class="">Branding</a></li>
                                <li><a href="">Interface Design</a></li>
                                <li><a href="">Emerging Technology Services</a></li>
                                <li><a href="">UI Solutions</a> </li>
                            </ul>

                        </div>
                        <div class="col-sm-6 col-lg-3">
                            <h4>Contact Us</h4>
                            <ul class="list-f03">
                                <li><a href="mailto:hr@njssolutions.in"><i class="fa-solid fa-envelope"></i> hr@njssolutions.in</a></li>
                                <li><a href=""><i class="fa-brands fa-skype"></i> NJSSolutions</a></li>
                                <li><a href="tel:918207869386"><i class="fa-solid fa-phone"></i> +91 8207869386</a></li>
                            </ul>
                            <h4>Find us on</h4>
                            <ul class="social-links clearfix">
                                <li><a href="" class="ion-social-facebook facebook" title="facebook " target="_blank"></a></li>
                                <li><a href="" class="ion-social-twitter twitter" target="_blank" title="twitter "></a></li>
                                <li><a href="" class="ion-social-linkedin linkedin" target="_blank" title="linkedin "></a></li>
                                <li><a href="" class="ion-social-googleplus plus" target="_blank" title="google plus"></a></li>
                            </ul>
                        </div>
                        <div class="col-sm-6 col-lg-4">
                            <h4>Newsletter</h4>
                            <div class="newsletter-box clearfix">
                                <form action="" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="validate" target="_blank" data-hs-cf-bound="true">

                                    <input type="email" name="EMAIL" id="mce-EMAIL" value="Your Email address" class="txt-box" onfocus="if (this.value == 'Your Email address') this.value = ''" onblur="if (this.value == '') this.value = 'Your Email address'" />
                                    <button class="subscribe-btn hvr-grow-shadow">Subscribe Now!</button>
                                    <p>Enter your email ID above to subscribe to our newsletter.</p>
                                </form>
                            </div>
                            <a href="" class="f-award"><span> 16 International Awards</span></a>
                            <div class="f-certification">
                                <div class="col-xs-6">
                                    <a href="" target="_blank"><img src="https://cdn.acodez.in/wp-content/themes/acodez-theme/images/google-partner.png" alt="" /></a>
                                </div>
                                <div class="col-xs-6">
                                    <a href="#"><img src="https://cdn.acodez.in/wp-content/themes/acodez-theme/images/bing-ads.png" alt="" /></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Footer2
