import React from 'react'
import './PlayGround.css'
import ScrollAnimation from 'react-animate-on-scroll';
import "animate.css/animate.compat.css"
const PlayGround = () => {
  return (
    <section className='play-ground'>
      <div class=" container">
        <div class="row flex item-center">
        
            <div class="col-lg-8 col-sm-6 acodie-pic bounceInLeft animated">
                <img loading="lazy" src="https://cdn.acodez.in/wp-content/themes/acodez-theme/images/cycle-3.gif" alt="Acodie"/>
            </div>
            <div class="col-lg-4 col-sm-6 bounceInRight animated">

                <div class="flip-container">
                    <div class="flipper">
                        <div class="front">
                            <h3>Acodie</h3>
                            <h5>Our Mascot</h5>
                        </div>
                        <div class="back">

                            We love illustrations and Acodie, our mascot, is a cool showpiece of our creativity. The
                            theme of this website is based on Acodie, wherein you could find Acodie in every page of
                            this site - in various styles and emotions!


                        </div>
                    </div>
                </div>
              
            </div>
        </div>
    </div>
    </section>
  )
}

export default PlayGround
