import React from 'react'
import './Products.css'
import Header from '../../header/Header'
import Footer from '../../Footer1/Footer'
import Footer2 from '../../footer2/Footer2'
const Products = () => {
  return (
    <div className='product'>
    <Header/>
        <section class="inner-page">
<div class="inner-banner product-img1">
		<div class="img "></div>
		<img src="https://cdn.acodez.in/wp-content/themes/acodez-theme/images/product-bannner.png" alt=""/>
		<div class="caption">
            <div class="container">
				<div class="row">
                    <div class="col-md-6 col-md-offset-6">
                    <h2>Our Product Brands</h2>
                    <p>Even though NJS Solutions is graded as the leading web design company in India, we also have a full-fledged product division where we have built some highly successful products that are being used by thousands of customers across the globe. We’ve more than 20 products across different verticals and a bunch of brands that cater to segments like eCommerce, E-Learning, SEO, Support and Maintenance, Training, etc. Below are some of our products/brands:</p>
                    </div>
				</div>
			</div>
		</div>
	</div>
	{/* <!-- product-1 --> */}
    <div class="total">
	<div class="service product-1">
		<div class="container">
			<div class="row">
					<div class="col-md-6">
                    <div class="cont">
                    <h4>Acowebs</h4>
				<h3>15+ Products to Supercharge Your eCommerce Stores</h3>
				<p>Acowebs is one of the leading eCommerce plugin and app development companies in the world. Acowebs is an award-winning company with 15+ pro products and a customer base of more than 60,000 active customers, many of which are leading in their own categories and have an overall rating of 4.8/5. Currently, our portfolio includes 15 WooCommerce plugins and 2 Shopify Apps.</p>
				<div class="row">
					<div class="col-sm-6" style={{marginTop: "10px"}}>
						<a href="https://acowebs.com/" target="_blank" class="btn-1">Visit site </a>
					</div>
				</div>
			</div>
            </div>
                    <div class="col-md-6 pic">
						<div class="pic-img">
							<img src="https://acodez.in/wp-content/themes/acodez-theme/images/product-1-1.svg" alt="product-img"/>
						</div>
					</div>
			</div>
		</div>
	</div>
    {/* <!-- product-2 --> */}
    <div class="service product-2">
		<div class="container">
			<div class="row">
            <div class="col-md-6 pic">
				<img src="https://cdn.acodez.in/wp-content/themes/acodez-theme/images/product-2-2-2.png" alt="product-img"/>
			</div>
        <div class="col-md-6">
            <div class="cont">
            <h4>Aviv Digital</h4>
				<h3>IT Training Institute with 2000 + Alumni</h3>
				<p>Aviv Digital is a training vertical of NJS Solutions and since 2017, we have trained more than 2000 students in Digital Marketing and various Programming Languages. Aviv Digital possesses an overall rating of 4.99 in public from our students and a near-100% placement record in the past 7 years., through our groundbreaking training methods.</p>
				<div class="row">
						<div class="col-sm-6" style={{marginTop:" 10px"}}>
						<a href="https://avivdigital.in/" target="_blank" class="btn-1">Visit site </a>
						</div>
				</div>
			</div>
        </div>
			</div>
		</div>
	</div>
	{/* <!-- product-3 --> */}
	<div class="service product-3">
		<div class="container">
			<div class="row">
					<div class="col-md-6">
                    <div class="cont">
                    <h4>Educational App Development Company</h4>
				<h3>E-Learning Mobile App for Traning Institutes and Coaches</h3>
				<p>Educational App Development Company offers a robust, scalable, and feature-rich e-learning app platform that can be easily customized to suit your business. This super easy to manage App is built in Flutter with the back-end admin panel in the MERN stack. It’s equipped with every feature to take your coaching business online through your own branded mobile app!
				</p>
				<div class="row">
					<div class="col-sm-6" style={{marginTop:" 10px"}}>
						<a href="https://educationalappdevelopment.com/" target="_blank" class="btn-1">Visit site </a>
					</div>
				</div>
			        </div>
                    </div>
			        <div class="col-md-6 pic">
						<img src="https://cdn.acodez.in/wp-content/themes/acodez-theme/images/product-3-3-3.png" alt="product-img"/>
					</div>
			</div>
		</div>
	</div>
    {/* <!-- product-4 --> */}
    <div class="service product-4">
		<div class="container">
			<div class="row">
                <div class="col-md-6 pic">
                    <img src="https://cdn.acodez.in/wp-content/themes/acodez-theme/images/product-4-4-4.png" alt="product-img"/>
                </div>
        <div class="col-md-6">
            <div class="cont">
            <h4>SEOBirth.com</h4>
				<h3>Curated SEO Outreach Database for Link Builders</h3>
				<p>SEOBirth is an SEO link-building tool that every SEO should have! It’s a curated list of quality link-building opportunities and details on how to contact them. If you’re spending a lot of your SEO time on link research, then SEOBirth can help you cut to the chase without wasting time researching link opportunities. SEOBirth is a regularly updated database and comes with a yearly subscription.</p>
				<div class="row">
						<div class="col-sm-6" style={{marginTop:" 10px"}}>
						<a href="https://seobirth.com/" target="_blank" class="btn-1">Visit site </a>
						</div>
                </div>
            </div>
        </div>
            </div>
		</div>
	</div>
    {/* <!-- product-5 --> */}
    <div class="service style product-5" style={{padding:" 10px"}}>
		<div class="container">
			<div class="row">
					<div class="col-md-6">
                    <div class="cont">
                    <h4>WPclerks.com</h4>
				<h3>24x7 WordPress Support and Maintenance Packages</h3>
				<p>WP Clerks is a WordPress Support provider that offers the best WordPress website maintenance services as monthly packages to our customers. In short, we take care of the complete headaches of maintaining a WordPress website - be it WordPress plugin updates, Theme updates, Security patching, Backups, Malware removal, Speed optimization, or even custom Design and Development - so you can just focus on growing your business.</p>
				<div class="row">
                        <div class="col-sm-6" style={{marginTop:" 10px"}}>
                            <a href="https://wpclerks.com/" target="_blank" class="btn-1">Visit site </a>
                        </div>
                    </div>
			        </div>
                </div>
                    <div class="col-md-6 pic">
						<img src="https://cdn.acodez.in/wp-content/themes/acodez-theme/images/product-5.jpg" alt="product-img"/>
					</div>
            </div>
        </div>
    </div>
    </div>
	{/* <!-- end services --> */}
 </section>
 <Footer />
            <Footer2 />
    </div>
  )
}

export default Products
