import React from 'react'
import './Header.css'
import {Link } from "react-router-dom";
import { $ }  from 'react-jquery-plugin'
import logo from '../../img/logo-njs.png'
import Form from '../form/Form';
const Header = () => {

    function formshow() {
        let form=document.getElementById('form')

        form.classList.toggle('form-show')
    }
    const navBarShow=()=>{
          let navTop = document.getElementById('navTop')
          navTop.classList.toggle('nav-top-show')


          let header = document.getElementById('header')
          header.classList.toggle('header-scroll')

          
          let xIcon2 = document.getElementById('x-icon2')
          xIcon2.classList.remove('nav-bar-icon2')
          
          let xIcon = document.getElementById('x-icon')
          xIcon.classList.add('nav-bar-icon2')
          
    }
   const navBarShow2=()=>{


    let navTop = document.getElementById('navTop')
    navTop.classList.toggle('nav-top-show')


    let header = document.getElementById('header')
    header.classList.toggle('header-scroll')
    let xIcon2 = document.getElementById('x-icon2')
    xIcon2.classList.add('nav-bar-icon2')
    let xIcon = document.getElementById('x-icon')
          xIcon.classList.remove('nav-bar-icon2')
   }


    $(window).scroll(function(){

        let ar = $(window).scrollTop();
      
      
      
          if(ar > 1){
      
            let header = document.getElementById('header')
            header.classList.add('header-scroll')

            
              
          }
          else{
            let header = document.getElementById('header')
              
            header.classList.remove('header-scroll')
          }
        });
        const text=()=>{
            document.getElementById('change-text').innerHTML="About"
            document.getElementById('text1').innerHTML="NJS Solutions has won more than 16 international awards, competing with the best agencies in the world. These recognitions, presented by the leading Industry Associations in the world stand testimony to our creativity, technical skills and quality standards."
        }
        const text1=()=>{
            document.getElementById('change-text').innerHTML="Awards"
            
            document.getElementById('text1').innerHTML="NJS Solutions has won more than 16 international awards, competing with the best agencies in the world. These recognitions, presented by the leading Industry Associations in the world stand testimony to our creativity, technical skills and quality standards."
        }
        const text2=()=>{
            document.getElementById('change-text').innerHTML="Services"
            document.getElementById('text1').innerHTML="We offer a wide array of services to cater to any of your web, mobile, or digital marketing requirements. Be it through the creativity of our designs, usage of latest technologies or adherence to industry best practices, we always thrive to deliver world class solutions to our clients."
        }
        const text3=()=>{
            document.getElementById('change-text').innerHTML="Products"
            document.getElementById('text1').innerHTML="We've built some outstanding products which are being used by customers across the globe. Acowebs, our eCommerce plugins and extensions division has more than 50,000 users. And our customizable e-Learning mobile app product currently handles 1M+ active users for our various edtech clients."
        }
        const text4=()=>{
            document.getElementById('change-text').innerHTML="Work"
            document.getElementById('text1').innerHTML="At NJS Solutions, we're obsessed with quality. And we believe, the same reason made us the ideal choice for hundreds of satisfied customers across the globe - 70+ Countries as of now, span across all 6 Continents."
        }
        const text5=()=>{
            document.getElementById('change-text').innerHTML="Technologies"
            document.getElementById('text1').innerHTML="We've always been at the forefront of technological advancements. We use cutting edge technologies, advanced frameworks and proven methodologies to ensure that our solutions are future-ready and scalable."
        }
        const text6=()=>{
            document.getElementById('change-text').innerHTML="Careers"
            document.getElementById('text1').innerHTML="NJS Solutions is rated as one of the top digital agencies in India and one main reason for this is the quality benchmark that we set for ourselves. If you believe that you can help us conquer the skies and above, then, this is a golden opportunity for you. Come, be part of our story!"
        }
        const text7=()=>{
            document.getElementById('change-text').innerHTML="Blog"
            document.getElementById('text1').innerHTML="Explore the latest trends and find our updates on all you need to know about what is happening in the world of web and technology."
        }
        const text8=()=>{
            document.getElementById('change-text').innerHTML="Contact"
            document.getElementById('text1').innerHTML="In India, we're based out of Gurgaon, Mumbai, Bangalore and Calicut. If you're from any other part of the world, we're just an email or phone call away!"
        }
    return (
        <>

            <section className='header' id='header'>
                <header>
                    <div className='container'>


                        <nav>
                            <div className='logo-img'>
                                <img src={logo} alt='logo'></img>
                            </div>
                            <div className='ul-1'>
                                <ul>
                                    <li><Link to='/'><i class="fa-solid fa-house"></i></Link></li>
                                    <li><Link to='/work'>Work</Link></li>
                                    <li><Link to='/services'>Services</Link></li>
                                    <li><Link to='/products'>Products</Link></li>
                                    <li><Link to='/technologies'>Technologies</Link></li>
                                    <li><Link to='/blog'>Blog</Link></li>

                                    <li><Link to='/contact'>Contact</Link></li>


                                </ul>
                                <a href='#' className="button-1" onClick={formshow}>
                                    <span>Quick Enquiry</span>
                                </a>
                            </div>
                            <a href="#" onClick={navBarShow2} id="x-icon2"class="nav-bar-icon nav-bar-icon2">
                                <i  class="fa-solid fa-x "></i>
                            </a>
                            <a href="#" onClick={navBarShow} id="x-icon"class="nav-bar-icon ">
                                <i  class="fa-solid fa-bars "></i>
                            </a>
                            


                        </nav>

                        <div className='nav-top' id='navTop'>

                            <div class="menu">
                                <div class="container">
                                    <nav>
                                        <div className='nav-top-child1'>
                                            <ul>
                                                <li ><Link to='/about' onMouseEnter={text}>About</Link></li>
                                                {/* <li><a href='#' onMouseEnter={text1}>Awards</a></li> */}
                                                <li><a href=''  onMouseEnter={text2}>Services</a></li>
                                                
                                                <li><Link to='/products'  onMouseEnter={text3}>Products</Link></li>
                                                <li><Link to='/work'  onMouseEnter={text4}>work</Link></li>
                                                <li><Link to='/technologies'  onMouseEnter={text5}>Technologies</Link></li>
                                                {/* <li><Link to='/careers'  onMouseEnter={text6}>Careers</Link></li> */}
                                                <li><Link to='/blog'  onMouseEnter={text7}>Blog</Link></li>
                                                <li><Link to='/contact'  onMouseEnter={text8}>Contact</Link></li>



                                            </ul>
                                        </div>

                                        <div className='nav-top-child2'>
                                            <p id='text1'>NJS Solutions is a multi-international award winning digital agency, with offices in Gurgaon, Mumbai, Bangalore and Calicut in India. Started in 2011, NJS Solutions has more than 600 satisfied customers spread across 70+ Countries.</p>
                                            <span class="menu-txt" id='change-text'>about</span>
                                        </div>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>
            </section>
            <Form/>
        </>
    )
}

export default Header
