import React from 'react'
import './Services.css'
import Header from '../../header/Header'
import Footer from '../../Footer1/Footer'
import Footer2 from '../../footer2/Footer2'


const Services = () => {
    

    return (
        <div className='services'>
          <Header/>
            <section class="inner-page">
                <div class="inner-banner ser-001">
                    <div class="img" div>
                        <img src="https://cdn.acodez.in/wp-content/themes/acodez-theme/images/service-banner.jpg" alt="" />
                        <div class="caption">
                            <div class="container">
                                <div class="row">
                                    <div class="col-md-6 col-md-offset-6">
                                        <h2>Services</h2>
                                        <p>
                                            Omni-channel is our call, and we will fuse it into all your business solutions to satisfy each customer. Blending in the perfect essence of flexibility in our delivery models with mature methodologies, innovative technologies, and unbeatable domain expertise, As a <a href="">web development company in India</a> for your business, Acodez will design a progressive workflow for your application development process.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="container">
                        <div class="title-4">We offer a wide array of services to cater to any of your web, mobile, or digital marketing requirements. Check out below:</div>

                    </div>

                    <div class="service service-1">
                        <div class="container">
                            <div class="row">
                                <div class="col-md-6">
                                    <h3><a href="https://acodez.in/ux-design"> UX Design</a></h3>
                                    <p>UX drives UI. Our team of UX designers at Acodez have ample of years of experience in the core areas of user experience, which helps them collaborate the finer modules of usability and functionality in-line with business goals and end-user needs.</p>
                                    <h4>Our UX Design Services</h4>
                                    <div class="row">
                                        <div class="col-sm-6">
                                            <ul class="list-s2">
                                                <li><span>UX Research</span></li>
                                                <li><span>Information Architecture</span></li>
                                                <li><span>Mood Boards</span></li>
                                                <li><span>Wire framing</span></li>
                                            </ul>
                                        </div>
                                        <div class="col-sm-6">
                                            <ul class="list-s2">
                                                <li><span>Interaction Design</span></li>
                                                <li><span>Information Architecture</span></li>
                                                <li><span>UX Testing </span></li>
                                            </ul>
                                        </div>
                                        <div class="col-sm-9">
                                            <a href="https://acodez.in/ux-design" class="btn-1">View more </a>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <!-- service 2 --> */}
                    <div class="service service-2">
                        <div class="container">
                            <div class="row">
                                <div class="col-md-6 col-md-offset-6">
                                    <h3><a href="https://acodez.in/mobile-app-development/">Mobile</a></h3>
                                    <p>Intuitive designs combined with compelling user experience are what makes our apps stand ahead in the digital age. Seamless experience driven by international standards in collaboration with out-of-the-box ideas is the specialty of Acodez’s apps helping your business accomplish its goals.</p>
                                    <h4>Our Mobile App Solutions</h4>
                                    <div class="row">
                                        <div class="col-sm-6">
                                            <ul class="list-s2">
                                                <li><span>Android App Development</span></li>
                                                <li><span>React Native App Development</span></li>
                                                <li><span>iPhone Development</span></li>
                                                <li><span>iPad App Development</span></li>
                                                <li><span>Apple Watch App Development</span></li>
                                            </ul>
                                        </div>
                                        <div class="col-sm-6">
                                            <ul class="list-s2">

                                                <li><span>Windows Mobile App Development</span></li>
                                                <li><span>PhoneGap App Development</span></li>
                                                <li><span>Xamarin App Development</span></li>
                                            </ul>
                                        </div>
                                        <div class="col-sm-9">
                                            <a href="https://acodez.in/mobile-app-development/" class="btn-1">View more </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <!-- service 3 --> */}
                    <div class="service service-3">
                        <div class="container">
                            <div class="row">
                                <div class="col-md-6 ">
                                    <h3><a href="https://acodez.in/web-development/">Web Development</a></h3>
                                    <p>Cross-browser and cross-device compatibility driven by mobile responsiveness all under one roof<br />
                                        Starting from basic website designs, including CMS and online store building to highly complex business website apps and design solutions, we will customize the best of web development solutions for you. </p>
                                    <h4> Our Web Development Services</h4>
                                    <div class="row">
                                        <div class="col-sm-6 custom-list">
                                            <ul class="list-s2">
                                                <li><span>Custom Application Development</span>
                                                </li>
                                                <li><span>Content Management System (CMS)</span></li>

                                            </ul>
                                        </div>
                                        <div class="col-sm-6">
                                            <ul class="list-s2">
                                                <li><span>Ecommerce Development</span></li>
                                                <li><span>MEAN Stack Development </span></li>
                                                <li><span>Social Media Apps </span></li>
                                            </ul>
                                        </div>
                                        <div class="col-sm-9">
                                            <a href="https://acodez.in/web-development" class="btn-1">View more </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <!-- service 4 --> */}
                    <div class="service service-4">
                        <div class="container">
                            <div class="row">
                                <div class="col-md-6">
                                    <ul class="list-03">
                                        <li class="dm-1">
                                            <span>Social Media Marketing</span>
                                        </li>
                                        <li class="dm-2">
                                            <span>Search Engine Optimization</span>
                                        </li>
                                        <li class="dm-3">
                                            <span>Email Marketing</span>
                                        </li>
                                        <li class="dm-4">
                                            <span>Inbound Marketing</span>
                                        </li>
                                    </ul>
                                </div>
                                <div class="col-md-6">
                                    <h3><a href="https://acodez.in/digital-marketing">Digital marketing</a></h3>
                                    <p>Developing a long-term and reliable marketing strategy that will flourish and live a longer life, while ensuring your customers stay with you and tell the world the tales of your business’s awesomeness transforming our custom-made business strategies into real time success.</p>
                                    <h4>Our Digital Marketing Services</h4>
                                    <div class="row">
                                        <div class="col-sm-6">
                                            <ul class="list-s2">
                                                <li><span>Inbound Marketing</span></li>
                                                <li><span>SEO</span></li>
                                                <li><span>SEM &amp; PPC</span></li>
                                                <li><span>Social Media Optimization</span></li>
                                                <li><span>Analytics Consultation</span></li>
                                            </ul>
                                        </div>
                                        <div class="col-sm-6">
                                            <ul class="list-s2">

                                                <li><span>Online Reputation Management</span></li>
                                                <li><span>Content Marketing</span></li>
                                                <li><span>Email Marketing</span></li>
                                            </ul>
                                        </div>
                                        <div class="col-sm-9">
                                            <a href="https://acodez.in/digital-marketing" class="btn-1">View more </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <!-- service 5 --> */}
                    <div class="service service-5">
                        <div class="container">
                            <div class="row">
                                <div class="col-md-6">
                                    <h3><a href="https://acodez.in/branding">Branding</a></h3>
                                    <p><b>Let your brand do the talking</b><br />
                                        Crafting a brand identity for your business that will convey your intention, goals and all that you intend to do for your audience - embedded inside a single design - connecting your business with your customers. </p>
                                    <h4>Our Brand Identity Solutions</h4>
                                    <div class="row">
                                        <div class="col-sm-6">
                                            <ul class="list-s2">
                                                <li><span>Logo &amp; Brand Identity</span></li>
                                                <li><span>Graphic Design</span></li>
                                                <li><span>Explainer Videos</span></li>
                                                <li><span>Infographics Design</span></li>
                                            </ul>
                                        </div>
                                        <div class="col-sm-6">
                                            <ul class="list-s2">

                                                <li><span>Print Design</span></li>
                                                <li><span>Creative Content Writing</span></li>
                                            </ul>
                                        </div>
                                        <div class="col-sm-9">
                                            <a href="https://acodez.in/branding" class="btn-1">View more </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <!-- service 6 --> */}
                    <div class="service service-6">
                        <div class="container">
                            <div class="row">
                                <div class="col-md-6 col-md-offset-6">
                                    <h3><a href="https://acodez.in/interface-design/">Interface Design</a></h3>
                                    <p><b>UI designs – web and mobile apps</b><br /> Creative, attractive, engaging and usable interfaces are the soul of any designs. We will help design custom user interfaces based on your business requirements for any of your device platforms, including mobile apps, tablets, desktops and KIOSKS. </p>
                                    <h4>Our Interface Services</h4>
                                    <div class="row">
                                        <div class="col-sm-6">
                                            <ul class="list-s2">
                                                <li><span>Web Design</span></li>
                                                <li><span>UI Design</span></li>
                                                <li><span>Mobile UI Design</span></li>
                                                <li><span>Landing Page Design</span></li>
                                            </ul>
                                        </div>
                                        <div class="col-sm-6">
                                            <ul class="list-s2">

                                                <li><span>Blog Design</span></li>
                                                <li><span>Designing for Wearables </span></li>
                                            </ul>
                                        </div>
                                        <div class="col-sm-9">
                                            <a href="https://acodez.in/interface-design/" class="btn-1">View more </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <!-- service 7 --> */}
                    <div class="service service-7">
                        <div class="container">
                            <div class="row">
                                <div class="col-md-6">
                                    <h3><a href="https://acodez.in/emerging-technology/">Emerging Technology</a> </h3>
                                    <p><b>Connecting the real world and virtual world in real time</b><br />
                                        Regardless of the emerging technology that you prefer for your business - Big data, cloud-based services, business intelligence solutions, Internet of Things, wearable apps and Apple app development now at your fingertips, while ensuring that your business flourishes and acquires customer sales.</p>
                                    <h4>Our Emerging Technology Services</h4>
                                    <div class="row">
                                        <div class="col-sm-6">
                                            <ul class="list-s2">
                                                <li><span>Chatbot Development</span></li>
                                                <li><span>IoT Applications</span></li>
                                                <li><span>Wearable App development</span></li>
                                                <li><span>Augmented Reality Apps</span></li>
                                            </ul>
                                        </div>
                                        <div class="col-sm-6">
                                            <ul class="list-s2">

                                                <li><span>Big Data Services </span></li>
                                                <li><span>Cloud Computing</span></li>
                                            </ul>
                                        </div>
                                        <div class="col-sm-9">
                                            <a href="https://acodez.in/emerging-technology/" class="btn-1">View more </a>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6 pic">
                                    <img src="https://cdn.acodez.in/wp-content/themes/acodez-theme/images/service-emerging.jpg" alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <!-- service 8 --> */}
                    <div class="service service-8">
                        <div class="container">
                            <div class="row">
                                <div class="col-md-6 pic">
                                    <img src="https://cdn.acodez.in/wp-content/themes/acodez-theme/images/service-ui.jpg" alt="" />
                                </div>
                                <div class="col-md-6 ">
                                    <h3><a href="https://acodez.in/ui-development/">UI Development Solutions</a></h3>
                                    <p>Enterprise-wide solutions transforming highly complex business requirements into simplified user interfaces bringing about a widespread user engagement is what we promise as part of our UI solutions.</p>
                                    <h4>Our UI Development Services</h4>
                                    <div class="row">
                                        <div class="col-sm-6">
                                            <ul class="list-s2">
                                                <li><span>Angular JS</span></li>
                                                <li><span>jQuery</span></li>
                                                <li><span>Bootstrap</span></li>
                                            </ul>
                                        </div>
                                        <div class="col-sm-6">
                                            <ul class="list-s2">
                                                <li><span>Foundation</span></li>
                                                <li><span>React JS</span></li>
                                            </ul>
                                        </div>
                                        <div class="col-sm-9">
                                            <a href="https://acodez.in/ui-development/" class="btn-1">View more </a>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    {/* <!-- end services --> */}
                    {/* <div class="whats-new">
                        <div class="container">
                            <div class="row">
                                <div class="col-md-10 col-sm-12 cnt">
                                    <h3>What’s new</h3>
                                    <div class="whats-new-slide swipe-slider swiper-container-horizontal swiper-container-fade">
                                        <div class="swiper-wrapper" ><div class="item swiper-slide swiper-slide-duplicate" data-swiper-slide-index="10" >
                                            <p>Just Inaugurated - our 4th office in India at UL CyberPark SEZ, Calicut
                                            </p>
                                        </div>










                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="bottom-sec1">
                        <div class="container">
                            <div class="row">
                                <div class="col-lg-4 col-sm-6 box-01">
                                    <h2>Recent Works</h2>
                                    <div class="slider-1 swipe-slider swiper-container-horizontal">
                                        <div class="" >

                                            <Swiper
                                                slidesPerView={1}
                                                spaceBetween={0}

                                                pagination={{
                                                    clickable: true,
                                                }}
                                                modules={[Pagination]}
                                                className="mySwiper2"
                                            >
                                                <SwiperSlide>
                                                    <div class="item swiper-slide swiper-slide-duplicate" data-swiper-slide-index="8" style={{ width: " 370px;" }}>
                                                        <a href="#" class="pic">
                                                            <img src="https://cdn.acodez.in/wp-content/themes/acodez-theme/images/acodez-featured/_0008_lameron.jpg" alt="" />
                                                        </a>
                                                    </div>
                                                </SwiperSlide>
                                                <SwiperSlide>
                                                    <div class="item swiper-slide" data-swiper-slide-index="0" style={{ width: " 370px;" }}>
                                                        <a href="#" class="pic">
                                                            <img src="https://cdn.acodez.in/wp-content/themes/acodez-theme/images/acodez-featured/_0000_anchor.jpg" alt="" />
                                                        </a>
                                                    </div>
                                                </SwiperSlide>
                                            </Swiper>





                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-sm-6 box-01">
                                    <h2>Read Our Blog</h2>
                                    <div class="slider-1 swipe-slider swiper-container-horizontal">
                                        <div class="swiper-wrapper" >

                                            <Swiper
                                                rewind={true}
                                                breakpoints={{
                                                    576: {
                                                        width: 576,
                                                        slidesPerView: 1,
                                                    },
                                                    768: {
                                                        width: 768,
                                                        slidesPerView: 1,
                                                    },
                                                }}
                                                spaceBetween={30}

                                                pagination={{
                                                    clickable: true,
                                                }}
                                                modules={[Pagination]}
                                                className="mySwiper"
                                            >
                                                <SwiperSlide>
                                                    <div class="item swiper-slide swiper-slide-duplicate" data-swiper-slide-index="24" style={{ width: " 370px;" }}>
                                                        <a href="https://acodez.in/ethics-of-ai-in-web-design/" class="pic">
                                                            <img src="https://cdn.acodez.in/wp-content/uploads/bfi_thumb/Feature-image-z7tsf11d8g1m5dw4pe0934.jpg" />                       	     <div class="caption">
                                                                The Ethics of AI in Web Design: Striking a Balance Between Automation and Creativity								</div>



                                                        </a>
                                                    </div>
                                                </SwiperSlide>
                                                <SwiperSlide>
                                                    <div class="item swiper-slide" data-swiper-slide-index="0" style={{ width: " 370px;" }}>
                                                        <a href="https://acodez.in/youtube-analytics/" class="pic">
                                                            <img src="https://cdn.acodez.in/wp-content/uploads/bfi_thumb/Feature-image-1-zm1yzn2g4vk2u3g60y7pq8.jpg" />                       	     <div class="caption">
                                                                YouTube Analytics: 15 Metrics to Track Performance								</div>



                                                        </a>
                                                    </div>
                                                </SwiperSlide>





                                            </Swiper>

                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-xs-12 box-01">
                                    <h2>Happy Clients</h2>
                                    <div class="white-bg">
                                        <span class="icon"></span>
                                        <div class="slider-2 swipe-slider swiper-container-horizontal">
                                            <div class="swiper-wrapper" > 
                                            <Swiper
                  rewind={true}
                  breakpoints={{
                                576: {
                                width: 576,
                                slidesPerView: 1,
                                },
                                768: {
                                width: 768,
                                slidesPerView: 1,
                                },
                            }}
                    spaceBetween={30}
                  
                    pagination={{
                        clickable: true,
                    }}
                    modules={[Pagination]}
                    className="mySwiper"
                >

                                                    <SwiperSlide>


                                                        <div class="item swiper-slide swiper-slide-duplicate swiper-slide-duplicate-active" data-swiper-slide-index="3" style={{ width: "310px;" }}>

                                                            <p>Acodez team delivered everything I wanted. Big Thanks!</p>
                                                            <div class="profile clearfix">
                                                                <div class="pic"><img src="https://cdn.acodez.in/wp-content/themes/acodez-theme/images/pro-pic-4.jpg" alt="" /></div>
                                                                <h5>Klaudyna Hebda</h5>
                                                                <span>Food Blogger, Poland</span>
                                                            </div>
                                                        </div>
                                                    </SwiperSlide>
                                                    <SwiperSlide>



                                                        <div class="item swiper-slide swiper-slide-duplicate-next" data-swiper-slide-index="0" style={{ width: "310px;" }}>

                                                            <p>Acodez handled my website's design and they did a tremendous job with this.</p>
                                                            <div class="profile clearfix">
                                                                <div class="pic"><img src="https://cdn.acodez.in/wp-content/themes/acodez-theme/images/pro-pic-simo.jpg" alt="" /></div>
                                                                <h5>Simo Bennis</h5>
                                                                <span>Seduction Naturelle PNL, France</span>
                                                            </div>
                                                        </div>

                                                    </SwiperSlide>

                                                </Swiper>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}
                </div>

            </section>
            <Footer />
            <Footer2 />
        </div>
    )
}

export default Services
