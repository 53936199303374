import React from 'react'
import './WebdesignSlider.css'
import { Swiper, SwiperSlide } from 'swiper/react';
import img from '../../img/02.webp'
import img1 from '../../img/09.webp'
import img2 from '../../img/07.webp'
import img3 from '../../img/09.webp'
import img4 from '../../img/07.webp'
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination } from 'swiper/modules';
{/* */}
const WebdesignSlider = () => {
  return (
    <section>
     <div class="webdesign-slider">
        <div class="container">
           
            <div class="slider-wrap">


            <Swiper
                  rewind={true}
                  breakpoints={{
                                576: {
                                width: 576,
                                slidesPerView: 1,
                                },
                                768: {
                                width: 768,
                                slidesPerView: 4,
                                },
                            }}
                    spaceBetween={30}
                  
                    pagination={{
                        clickable: true,
                    }}
                    modules={[Pagination]}
                    className="mySwiper"
                >
                <SwiperSlide>
                <div class="swiper-slide web-item hvr-sweep-to-top swiper-slide-duplicate"  data-swiper-slide-index="7">
                            <a href="javascript:void(0);">
                                <img loading="lazy" src={img} alt=""/>
                            </a>
                            <div class="text-overlay">
                                <div class="outer-overlay">
                                    <p>UX design and development for Remit Money, an Axis Bank Brand. We also developed
                                        the brand Identity design.</p>
                                    <a href="http://hereisthedemo.com/RemitMoney/html/" rel="nofollow" class="btn-1 white-cle" target="_blank">View Work</a>
                                </div>
                            </div>
                        </div>

                </SwiperSlide>
                <SwiperSlide>
                <div class="swiper-slide web-item hvr-sweep-to-top swiper-slide-duplicate" data-swiper-slide-index="7">
                            <a href="javascript:void(0);">
                                <img loading="lazy" src={img1} alt=""/>
                            </a>
                            <div class="text-overlay">
                                <div class="outer-overlay">
                                    <p>UX design and development for Remit Money, an Axis Bank Brand. We also developed
                                        the brand Identity design.</p>
                                    <a href="http://hereisthedemo.com/RemitMoney/html/" rel="nofollow" class="btn-1 white-cle" target="_blank">View Work</a>
                                </div>
                            </div>
                        </div>

                </SwiperSlide><SwiperSlide>
                <div class="swiper-slide web-item hvr-sweep-to-top swiper-slide-duplicate" data-swiper-slide-index="7">
                            <a href="javascript:void(0);">
                                <img loading="lazy" src={img3} alt=""/>
                            </a>
                            <div class="text-overlay">
                                <div class="outer-overlay">
                                    <p>UX design and development for Remit Money, an Axis Bank Brand. We also developed
                                        the brand Identity design.</p>
                                    <a href="http://hereisthedemo.com/RemitMoney/html/" rel="nofollow" class="btn-1 white-cle" target="_blank">View Work</a>
                                </div>
                            </div>
                        </div>

                </SwiperSlide>
                <SwiperSlide>
                <div class="swiper-slide web-item hvr-sweep-to-top swiper-slide-duplicate" data-swiper-slide-index="7">
                            <a href="javascript:void(0);">
                                <img loading="lazy" src={img4} alt=""/>
                            </a>
                            <div class="text-overlay">
                                <div class="outer-overlay">
                                    <p>UX design and development for Remit Money, an Axis Bank Brand. We also developed
                                        the brand Identity design.</p>
                                    <a href="http://hereisthedemo.com/RemitMoney/html/" rel="nofollow" class="btn-1 white-cle" target="_blank">View Work</a>
                                </div>
                            </div>
                        </div>

                </SwiperSlide>
                <SwiperSlide>
                <div class="swiper-slide web-item hvr-sweep-to-top swiper-slide-duplicate" data-swiper-slide-index="7">
                            <a href="javascript:void(0);">
                                <img loading="lazy" src="https://cdn.acodez.in/wp-content/themes/acodez-theme/images/wed-design-sliders/02.jpg" alt=""/>
                            </a>
                            <div class="text-overlay">
                                <div class="outer-overlay">
                                    <p>UX design and development for Remit Money, an Axis Bank Brand. We also developed
                                        the brand Identity design.</p>
                                    <a href="http://hereisthedemo.com/RemitMoney/html/" rel="nofollow" class="btn-1 white-cle" target="_blank">View Work</a>
                                </div>
                            </div>
                        </div>

                </SwiperSlide>
                <SwiperSlide>
                <div class="swiper-slide web-item hvr-sweep-to-top swiper-slide-duplicate" data-swiper-slide-index="7">
                            <a href="javascript:void(0);">
                                <img loading="lazy" src="https://cdn.acodez.in/wp-content/themes/acodez-theme/images/wed-design-sliders/02.jpg" alt=""/>
                            </a>
                            <div class="text-overlay">
                                <div class="outer-overlay">
                                    <p>UX design and development for Remit Money, an Axis Bank Brand. We also developed
                                        the brand Identity design.</p>
                                    <a href="http://hereisthedemo.com/RemitMoney/html/" rel="nofollow" class="btn-1 white-cle" target="_blank">View Work</a>
                                </div>
                            </div>
                        </div>

                </SwiperSlide>


                <SwiperSlide>
                <div class="swiper-slide web-item hvr-sweep-to-top swiper-slide-duplicate" data-swiper-slide-index="7">
                            <a href="javascript:void(0);">
                                <img loading="lazy" src="https://cdn.acodez.in/wp-content/themes/acodez-theme/images/wed-design-sliders/02.jpg" alt=""/>
                            </a>
                            <div class="text-overlay">
                                <div class="outer-overlay">
                                    <p>UX design and development for Remit Money, an Axis Bank Brand. We also developed
                                        the brand Identity design.</p>
                                    <a href="http://hereisthedemo.com/RemitMoney/html/" rel="nofollow" class="btn-1 white-cle" target="_blank">View Work</a>
                                </div>
                            </div>
                        </div>

                </SwiperSlide>
                <SwiperSlide>
                <div class="swiper-slide web-item hvr-sweep-to-top swiper-slide-duplicate" data-swiper-slide-index="7">
                            <a href="javascript:void(0);">
                                <img loading="lazy" src="https://cdn.acodez.in/wp-content/themes/acodez-theme/images/wed-design-sliders/02.jpg" alt=""/>
                            </a>
                            <div class="text-overlay">
                                <div class="outer-overlay">
                                    <p>UX design and development for Remit Money, an Axis Bank Brand. We also developed
                                        the brand Identity design.</p>
                                    <a href="http://hereisthedemo.com/RemitMoney/html/" rel="nofollow" class="btn-1 white-cle" target="_blank">View Work</a>
                                </div>
                            </div>
                        </div>

                </SwiperSlide>

                </Swiper>
                
                <div class="web-navigation">
                    <a href="javascript:void(0);" class="web-button-next"><span></span></a>
                    <a href="javascript:void(0);" class="web-button-prev"><span></span></a>
                </div>
            </div>

            <div class="slider-content">
                <h2>Web Design</h2>
                <p>We do not just web design, but out-of-the box digital experiences that takes your business to the
                    next level!</p>
                <br/>
                <p>Conceptual Websites</p>
                <p>Corporate Websites</p>
                <p>Experience Design (XD)</p>
            </div>
        </div>
    </div> 
    </section>
    
  )
}

export default WebdesignSlider
