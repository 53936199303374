import React from 'react'
import './Footer.css'
const Footer = () => {
  return (
    <section>
      <div class="footer-sec-1">
    <div class="bg"></div>
    <div class="container">
        <h4>Let's talk about what we can build together</h4>
        <p>Whatever may be your requirement - be it a simple website design, a complex data driven web application development, an ecommerce website, a native or cross platform mobile app development, a logo and brand identity design, a video production or a full fledged digital marketing campaign - we have a solution for you.</p>
        <a href="" class="btn-1 footer-btn">Contact us now for a free quote!</a>
    </div>
</div>
    </section>
  )
}

export default Footer
