import React from 'react'
import './Contact.css'
import Header from '../../header/Header'
import Footer from '../../Footer1/Footer'
import Footer2 from '../../footer2/Footer2'
const Contact = () => {
  return (
    <div className='contact'>
    <Header/>
    <section class="inner-page">
	<div class="inner-banner contact-img">
		<div class="img"></div>
		 <img src="https://cdn.acodez.in/wp-content/themes/acodez-theme/images/contact-banner.jpg" alt=""/> 
		<div class="caption">
				<div class="container">
				<div class="row">
				<div class="col-md-6 col-md-offset-6">
				<h2>Get In Touch</h2>
				<ul class="conact-numbers">
					<li> <a href="tel:918207869386" class="india"> <span>India</span> :91 8207869386</a> </li>
					<li> <a href="tel:+14084268844" class="us"> <span>US</span>      : +1 408 426 8844</a> </li>
					<li> <a href="tel:+447451274244" class="uk"> <span>UK</span>      : +44 745 127 4244</a> </li>
					<li> <a href="mailto:hr@njssolutions.in" class="email"> <span>Email</span> : hr@njssolutions.in</a> </li>
					<li> <a href="mailto:careers@acodez.in" class="email"> <span>Career</span> : careers@njssolutions.in</a> </li>
				</ul>
				</div>
				</div>
			</div>
		</div>
	</div>
	 
	<div class="contact-sec1 contact-top-sec1">
		<div class="container">
			<div class="enquiry-box">
				<h3>We'd love to hear from you!</h3>
				<p>Brief us your requirements below, and let's connect.</p>
				
				<form class="contact-form" method="post" data-hs-cf-bound="true">
				<div class="row">

					<div class="col-sm-6">
					
						<ul class="apply-now">
							<li>
							<label>Your name <span>*</span></label>
							<input type="text" name="name" required="required" class="input-txt" placeholder="Enter your name here"/>
						</li>
						<li>
							<label>Email <span>*</span></label>
							<input type="email" name="email" class="input-txt" required="required" placeholder="Enter your email here"/>
						</li>
						<li>
							<label>Contact Number</label>
							<input type="text" name="phone" class="input-txt" placeholder="Enter your number"/>
						</li>
						</ul>
					
					</div>
					<div class="col-sm-6 rht">
						<ul class="apply-now">
							<li>
								<label>Your requirement <span>*</span></label>
								<textarea name="message" required="" class="txt-area" placeholder="Brief us your requirement"></textarea>
							</li>
							<li class="clearfix">
							<input type="submit" name="submit" value="Send Message" class="submit-btn"/>
								<input type="hidden" name="action" value="contactusform"/>
								<input type="hidden" name="form" value="contact-page"/>
								<span class="progress"></span>
							</li>
						</ul>
						 <div class="status" style={{color:" #f00"}}></div>
						 <p style={{fontSize:" 16px;"}}>We respect your privacy. We promise we won't spam you :) </p>
					</div>
					
				</div>
				</form>
			</div>
		</div>
	</div>
    <div class="contact-location">
    <h1>Locations</h1>

    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d238130.15440691105!2d78.90769835448079!3d21.16100605022737!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bd4c0a5a31faf13%3A0x19b37d06d0bb3e2b!2sNagpur%2C%20Maharashtra!5e0!3m2!1sen!2sin!4v1714975285253!5m2!1sen!2sin" width="100%" height="450" style={{border:"0;"}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
    </div>	
	<div class="acodez-locations">
		
		
		{/* <div class="acodez-follow-us">
			<h4>Follow us on Social media</h4>
			<ul class="social-links clearfix">
					<li><a href="http://www.facebook.com/Acodez" class="ion-social-facebook facebook" title="facebook " target="_blank"></a></li>
					<li><a href="http://twitter.com/#!/Acodez" class="ion-social-twitter twitter" title="twitter " target="_blank"></a></li>
					<li><a href="https://plus.google.com/u/0/101337685849536037041/posts" class="ion-social-googleplus plus" title="google plus" target="_blank"></a></li>
					<li><a href="http://www.linkedin.com/company/acodez-it-solutions" class="ion-social-linkedin linkedin" title="linkedin " target="_blank"></a></li>
				</ul>
		</div>	 */}
	</div>	
	





	
 </section>
 <Footer />
			<Footer2 />
      
    </div>
  )
}

export default Contact
