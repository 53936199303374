import React from 'react'
import './HomeBlog.css'

import { Swiper, SwiperSlide } from 'swiper/react';

import img1 from '../../img/Feature-image.webp'
import img2 from '../../img/Feature-image-Error.webp'
import img3 from '../../img/Feature-imager-1.webp'
import img4 from '../../img/Feature-image-2.webp'
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';



// import required modules
import { Pagination } from 'swiper/modules';
const HomeBlog = () => {
  return (
    <section>
      <div class="home-blog">
        <div class="blog-preview bounceInRight animated">
        </div>
        <div class="title-right">Blog</div>
        <div class="container">
            <div class="row">
                <div class="col-lg-7">
                    <h2>Welcome to NJS Solutions blog!</h2>


                    <p class="blog-title">Explore the latest trends and find our updates on all you need to know about
                        what is happening in the world of web and technology.</p>
                    <div class="blog-slide swiper-container-horizontal swiper-container-multirow">
                        <div class="swiper-wrapper" style={{width:" 2832px;"}}>


                                <Swiper
                    slidesPerView={1}
                    spaceBetween={30}
                 
                    pagination={{
                        clickable: true,
                    }}
                    modules={[Pagination]}
                    className="mySwiper"
                >     
                <SwiperSlide>                
                            <div class="swiper-slide swiper-slide-active" data-swiper-column="0" data-swiper-row="0" >
                                <div class="row">
                                    <div class="col-sm-4 blog-pic">

                                        <img loading="lazy" src={img1} alt="YouTube Analytics: 15 Metrics to Track Performance"/>
                                    </div>
                                    <div class="col-sm-8">
                                        <div class="title">Posted on Apr 22, 2024 <a href="">Digital Marketing</a> </div>

                                        <h4 class="entry-title"><a href="" rel="bookmark">YouTube Analytics: 15 Metrics to Track Performance</a></h4>                                    </div>
                                </div>
                            </div>
                            
                            <div class="swiper-slide swiper-slide-next" data-swiper-column="0" data-swiper-row="1" >
                                <div class="row">
                                    <div class="col-sm-4 blog-pic">

                                        <img loading="lazy" src={img2} alt="10 IDEs and Tools for Golang Developers"/>
                                    </div>
                                    <div class="col-sm-8">
                                        <div class="title">Posted on Mar 12, 2024 <a href="https://acodez.in/category/web-design/">Web Design</a> </div>

                                        <h4 class="entry-title"><a href="" rel="bookmark">10 IDEs and Tools for Golang Developers</a></h4>                                    </div>
                                </div>
                            </div>
                            </SwiperSlide>  

                            <SwiperSlide> 
                            <div class="swiper-slide" data-swiper-column="1" data-swiper-row="0" >
                                <div class="row">
                                    <div class="col-sm-4 blog-pic">

                                        <img loading="lazy" src={img3} alt="Benefits of Using Agile Methodology in Software Development"/>
                                    </div>
                                    <div class="col-sm-8">
                                        <div class="title">Posted on Mar 08, 2024 <a href="">Web Development</a> </div>

                                        <h4 class="entry-title"><a href="" rel="bookmark">Benefits of Using Agile Methodology in Software Development</a></h4>                                    </div>
                                </div>
                            </div>
                            
                            <div class="swiper-slide" data-swiper-column="1" data-swiper-row="1" >
                                <div class="row">
                                    <div class="col-sm-4 blog-pic">

                                        <img loading="lazy" src={img4} alt="Barnacle SEO: What is It and How to Drive Traffic Without Ranking"/>
                                    </div>
                                    <div class="col-sm-8">
                                        <div class="title">Posted on Mar 01, 2024 <a href="https://acodez.in/category/digital-marketing/">Digital Marketing</a> </div>

                                        <h4 class="entry-title"><a href="https://acodez.in/barnacle-seo/" rel="bookmark">Barnacle SEO: What is It and How to Drive Traffic Without Ranking</a></h4>                                    </div>
                                </div>
                            </div>
                            </SwiperSlide>   
                            <SwiperSlide> 
                            <div class="swiper-slide" data-swiper-column="2" data-swiper-row="0" >
                                <div class="row">
                                    <div class="col-sm-4 blog-pic">

                                        <img loading="lazy" src={img1} alt="Go vs. Java: Which One to Pick for Web Development"/>
                                    </div>
                                    <div class="col-sm-8">
                                        <div class="title">Posted on Feb 28, 2024 <a href="">Web Development</a> </div>

                                        <h4 class="entry-title"><a href="" rel="bookmark">Go vs. Java: Which One to Pick for Web Development</a></h4>                                    </div>
                                </div>
                            </div>
                             
                            <div class="swiper-slide" data-swiper-column="2" data-swiper-row="1" >
                                <div class="row">
                                    <div class="col-sm-4 blog-pic">

                                        <img loading="lazy" src={img2} alt="The Evolution of Software Licensing Models"/>
                                    </div>
                                    <div class="col-sm-8">
                                        <div class="title">Posted on Feb 23, 2024 <a href="">Web Development</a> </div>

                                        <h4 class="entry-title"><a href="" rel="bookmark">The Evolution of Software Licensing Models</a></h4>                                    </div>
                                </div>
                            </div>
                            </SwiperSlide>   
                            <SwiperSlide> 
                            <div class="swiper-slide" data-swiper-column="3" data-swiper-row="0" >
                                <div class="row">
                                    <div class="col-sm-4 blog-pic">

                                        <img loading="lazy" src={img3} alt="Innovative Web Design Trends for 2024"/>
                                    </div>
                                    <div class="col-sm-8">
                                        <div class="title">Posted on Feb 21, 2024 <a href="">Web Design</a> </div>

                                        <h4 class="entry-title"><a href="" rel="bookmark">Innovative Web Design Trends for 2024</a></h4>                                    </div>
                                </div>
                            </div>
                            
                            <div class="swiper-slide" data-swiper-column="3" data-swiper-row="1" >
                                <div class="row">
                                    <div class="col-sm-4 blog-pic">

                                        <img loading="lazy" src={img4} alt="Top AI Statistics And Trends for 2024: The Age of Rapid Tech Transition"/>
                                    </div>
                                    <div class="col-sm-8">
                                        <div class="title">Posted on Feb 15, 2024 <a href="">Web Design</a> </div>

                                        <h4 class="entry-title"><a href="" rel="bookmark">Top AI Statistics And Trends for 2024: The Age of Rapid Tech Transition</a></h4>                                    </div>
                                </div>
                            </div>
                            </SwiperSlide>
                            </Swiper>
                                
            </div>
        </div>

        <a href="" class="btn-1">Read all blogs </a>
    </div>
    <div class="swiper-button-next"></div>
    <div class="swiper-button-prev swiper-button-disabled"></div>
    </div>
    </div>
    </div>
    </section>
  )
}

export default HomeBlog
