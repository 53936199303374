import React from 'react'
import './About.css'
import aIcon from '../../img/coding.png'
import ScrollAnimation from 'react-animate-on-scroll';
import "animate.css/animate.compat.css"


const About = () => {
  return (
    <section className='about'>

    <div className='container'>
    <h2>About</h2>
    <ScrollAnimation animateIn="fadeInUp" >
        <div className='about-box1'>

          <div className='about-box1-child1 '>
            <h5>We are an international award-winning IT company with 6 offices across India, and offers web
                        design, web development and digital marketing services.</h5>

          </div>
          <div className='about-box1-child1 none '>
            <p>NJS Solutions is rated as one of the top web agencies in India by various industry magazines and review
                        sites. We have a right blend of award-winning designers, expert web developers and Google
                        certified digital marketers which make us a unique one-stop solution for hundreds of our
                        clients, spread across 80+ countries.</p>

          </div>
        </div>
        </ScrollAnimation>

        <ScrollAnimation animateIn="fadeInUp" >
        <div className='about-box2'>
        <div className='about-card fadeInUp animated'>
            <div className='about-icon'>
                <img src={aIcon} alt='about-icon'></img>
            </div>
            <h4>Software Development</h4>
            <ul>
                <li>Web Application Development</li>
                <li>AI &amp; ML - Python, TensorFlow </li>
                <li>MEAN/MERN Stack Development</li>
                <li>BlockChain Development</li>
                <li>IoT Applications</li>
                <li>Big Data Services</li>
             </ul>
        </div>

        <div className='about-card margin-t fadeInUp animated'>
            <div className='about-icon'>
                <img src={aIcon} alt='about-icon'></img>
            </div>
            <h4>Software Development</h4>
            <ul>
                <li>Web Application Development</li>
                <li>AI &amp; ML - Python, TensorFlow </li>
                <li>MEAN/MERN Stack Development</li>
                <li>BlockChain Development</li>
                <li>IoT Applications</li>
                <li>Big Data Services</li>
             </ul>
        </div>

        
        <div className='about-card fadeInUp animated' >
            <div className='about-icon'>
                <img src={aIcon} alt='about-icon'></img>
            </div>
            <h4>Software Development</h4>
            <ul>
                <li>Web Application Development</li>
                <li>AI &amp; ML - Python, TensorFlow </li>
                <li>MEAN/MERN Stack Development</li>
                <li>BlockChain Development</li>
                <li>IoT Applications</li>
                <li>Big Data Services</li>
             </ul>
        </div>
        
        <div className='about-card margin-t fadeInUp animated'>
            <div className='about-icon'>
                <img src={aIcon} alt='about-icon'></img>
            </div>
            <h4>Software Development</h4>
            <ul>
                <li>Web Application Development</li>
                <li>AI &amp; ML - Python, TensorFlow </li>
                <li>MEAN/MERN Stack Development</li>
                <li>BlockChain Development</li>
                <li>IoT Applications</li>
                <li>Big Data Services</li>
             </ul>
        </div>
        <div className='about-card margin-b fadeInUp animated'>
            <div className='about-icon'>
                <img src={aIcon} alt='about-icon'></img>
            </div>
            <h4>Software Development</h4>
            <ul>
                <li>Web Application Development</li>
                <li>AI &amp; ML - Python, TensorFlow </li>
                <li>MEAN/MERN Stack Development</li>
                <li>BlockChain Development</li>
                <li>IoT Applications</li>
                <li>Big Data Services</li>
             </ul>
        </div>
        <div className='about-card fadeInUp animated'>
            <div className='about-icon'>
                <img src={aIcon} alt='about-icon'></img>
            </div>
            <h4>Software Development</h4>
            <ul>
                <li>Web Application Development</li>
                <li>AI &amp; ML - Python, TensorFlow </li>
                <li>MEAN/MERN Stack Development</li>
                <li>BlockChain Development</li>
                <li>IoT Applications</li>
                <li>Big Data Services</li>
             </ul>
        </div>
        <div className='about-card margin-b fadeInUp animated'>
            <div className='about-icon'>
                <img src={aIcon} alt='about-icon'></img>
            </div>
            <h4>Software Development</h4>
            <ul>
                <li>Web Application Development</li>
                <li>AI &amp; ML - Python, TensorFlow </li>
                <li>MEAN/MERN Stack Development</li>
                <li>BlockChain Development</li>
                <li>IoT Applications</li>
                <li>Big Data Services</li>
             </ul>
        </div>


        </div>
        </ScrollAnimation>
    </div>
      
    </section>
  )
}

export default About
