import React from 'react'
import Header from '../header/Header'
import Hero from '../hero/Hero'
import About from '../about/About'
import Won from '../won/Won'
import Work from '../work/Work'
import Slider1 from '../slider1/Slider1'
import Achievement from '../achievement/Achievement'
import Faq from '../faq/Faq'
import PlayGround from '../playGround/PlayGround'
import WebdesignSlider from '../webdesignSlider/WebdesignSlider'
import WebdeveloperSlider from '../webdeveloperSlider/WebdeveloperSlider'
import HomeBlog from '../homeBlog/HomeBlog'
import Footer from '../Footer1/Footer'
import Footer2 from '../footer2/Footer2'

const FrontPage = () => {
  return (
    <div>
      <Header/>
      <Hero/>
      <About/>
      <Won/>
      <Work/>
      <Slider1/>
      <WebdesignSlider/>
      <WebdeveloperSlider/>
      <Achievement/>
      <Faq/>
      <HomeBlog/>
      <PlayGround/>
      <Footer/>
      <Footer2/>
    </div>
  )
}

export default FrontPage
