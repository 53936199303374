import React from 'react'
import './Work.css'
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import ScrollAnimation from 'react-animate-on-scroll';
import "animate.css/animate.compat.css"

// import required modules
import { Pagination } from 'swiper/modules';
const Work = () => {
    return (
        <section className='work'>





            <div class="home-works container">
                <h2>Our Works</h2>
                <div class="work-content">
                    <h4>Obsessed with Quality!</h4>
                    <p>Yes, at Acodez, we're obsessed with quality. We rely on our portfolio to do business and always
                        ensure that each of our web or mobile deliverable is unique and world class.</p>

                </div>
                {/* <!-- mobile slider --> */}
                <div class="work-slider swiper-slider swiper-container-horizontal swiper-container-android">

                    <Swiper
                        slidesPerView={5}
                        spaceBetween={30}

                        pagination={{
                            clickable: true,
                        }}
                        modules={[Pagination]}
                        className="mySwiper"
                    >

                        <div class="swiper-wrapper" >
                        <SwiperSlide>
                            <div class="item swiper-slide swiper-slide-duplicate swiper-slide-prev" data-swiper-slide-index="5" style={{ width: "622px" }}>
                                <img loading="lazy" src="https://cdn.acodez.in/wp-content/themes/acodez-theme/images/works//webdesign-90plus.png" alt="" />
                            </div>
                            </SwiperSlide>

                            <SwiperSlide>
                            <div class="item swiper-slide swiper-slide-active" data-swiper-slide-index="0" style={{ width: " 622px" }}>
                                <img loading="lazy" src="https://cdn.acodez.in/wp-content/themes/acodez-theme/images/works/webdesign-kalayci-london.jpg" alt="" />
                                
                            </div>
                            </SwiperSlide>
                            <SwiperSlide>
                            <div class="item swiper-slide swiper-slide-next" data-swiper-slide-index="1" style={{ width: " 622px" }}>
                                <img loading="lazy" src="https://cdn.acodez.in/wp-content/themes/acodez-theme/images/works/webdesign-anchor.jpg" alt="" />
                                <div class="caption"></div>
                            </div>
                            </SwiperSlide>
                            <SwiperSlide>
                            <div class="item swiper-slide" data-swiper-slide-index="2" style={{ width: " 622px" }}>
                                <img loading="lazy" src="https://cdn.acodez.in/wp-content/themes/acodez-theme/images/works/webdesign-axis.jpg" alt="" />
                            </div>
                            </SwiperSlide>
                            <SwiperSlide>
                            <div class="item swiper-slide" data-swiper-slide-index="3" style={{ width: " 622px" }}>
                                <img loading="lazy" src="https://cdn.acodez.in/wp-content/themes/acodez-theme/images/works/webdesign-apml.jpg" alt="" />
                            </div>
                            </SwiperSlide>
                            <SwiperSlide>
                            <div class="item swiper-slide" data-swiper-slide-index="4" style={{ width: " 622px" }}>
                                <img loading="lazy" src="https://cdn.acodez.in/wp-content/themes/acodez-theme/images/works/anchor-gif-3.gif" alt="" />
                            </div>
                            </SwiperSlide>
                            <SwiperSlide>
                            <div class="item swiper-slide swiper-slide-duplicate-prev" data-swiper-slide-index="5" style={{ width: " 622px" }}>
                                <img loading="lazy" src="https://cdn.acodez.in/wp-content/themes/acodez-theme/images/works//webdesign-90plus.png" alt="" />
                            </div>
                           </SwiperSlide>
                           <SwiperSlide>
                            <div class="item swiper-slide swiper-slide-duplicate swiper-slide-duplicate-active" data-swiper-slide-index="0" style={{ width: " 622px" }}>
                                <img loading="lazy" src="https://cdn.acodez.in/wp-content/themes/acodez-theme/images/works/webdesign-kalayci-london.jpg" alt="" />
                                {/* <!-- <div class="caption">
							<h3>Anchor</h3>
							<span>India</span>
						</div> --> */}
                            </div>
                            </SwiperSlide>
                            </div>
                    </Swiper>



                    <div class="swiper-pagination swiper-pagination-clickable swiper-pagination-bullets"><span class="swiper-pagination-bullet swiper-pagination-bullet-active"></span><span class="swiper-pagination-bullet"></span><span class="swiper-pagination-bullet"></span><span class="swiper-pagination-bullet"></span><span class="swiper-pagination-bullet"></span><span class="swiper-pagination-bullet"></span></div>
                </div>
                {/* <!-- end mobile slider --> */}


                <div class="desk-work">
                    <div class="work-pic pic-1">
                        <img loading="lazy" src="https://cdn.acodez.in/wp-content/themes/acodez-theme/images/works/webdesign-kalayci-london.jpg" alt="" />
                        <div class="work-details">
                            <h4 style={{ color: " #fff" }}>E-commerce</h4>
                            <a href="https://kalaycilondon.com/" rel="nofollow" target="_blank">View Website</a>
                        </div>
                    </div>
                    <div class="work-pic pic-2 ">
                        <img loading="lazy" src="https://cdn.acodez.in/wp-content/themes/acodez-theme/images/works/webdesign-anchor.jpg" alt="" />
                        <div class="work-details">
                            <h4>Corporate Website</h4>
                            <a href="https://lsin.panasonic.com/" rel="nofollow" target="_blank" style={{ color: " #ED1D24" }}>View
                                Website</a>
                        </div>
                    </div>
                  
                    <a href="https://www.remitmoney.com/" target="_blank" class="work-pic pic-3 ">
                        <img loading="lazy" src="https://cdn.acodez.in/wp-content/themes/acodez-theme/images/works/webdesign-axis.jpg" alt="" />
                    </a>
                 
                    <div class="work-pic pic-4 ">
                        <img loading="lazy" src="https://cdn.acodez.in/wp-content/themes/acodez-theme/images/works/webdesign-apml.jpg" alt="" />
                        <div class="work-details">
                            <h4>Corporate Website</h4>
                            <a href="https://www.agarwalpackers.com/" rel="nofollow" target="_blank" style={{ color: " #ED1D24" }}>View Website</a>
                        </div>
                    </div>
                    <a href="https://lsin.panasonic.com/" rel="nofollow" target="_blank" class="work-pic pic-5">
                        <img loading="lazy" data-gif="https://cdn.acodez.in/wp-content/themes/acodez-theme/images/works/anchor-gif-3.gif" data-jpg="https://cdn.acodez.in/wp-content/themes/acodez-theme/images/works/panasonic.jpg" src="https://cdn.acodez.in/wp-content/themes/acodez-theme/images/works/panasonic.jpg" class="pic-jpg gif" alt="" />
                    </a>

                    <a href="https://mytuitionapp.com/" rel="nofollow" target="_blank" class="work-pic pic-6">
                        <img loading="lazy" src="https://cdn.acodez.in/wp-content/themes/acodez-theme/images/works/webdesign-90plus.png" alt="" />
                    </a>
                </div>


            </div>
        </section>
    )
}

export default Work
