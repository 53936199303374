import React from 'react'
import  './About.css'
import Header from '../../header/Header'
import Footer from '../../Footer1/Footer'
import Footer2 from '../../footer2/Footer2'
const About = () => {
  return (
    <div className='about-page'>
    <Header />
    <section class="inner-page">
        <div class="inner-banner work-img">
            <div class="img "></div>
            <img src="https://cdn.acodez.in/wp-content/themes/acodez-theme/images/about-banner.jpg" alt="" />
            <div class="caption">
                <div class="container">
                    <div class="row">
                        <div class="col-md-6 col-md-offset-6">
                            <h2>Who we are?</h2>
                            <p>NJS Solutions is a multi-international award-winning <a href="">web design company in India</a> specialising across various arenas of the IT and digital marketing industry. Based in India, with offices in Delhi NCR, Mumbai, Bangalore and Calicut, we cater to the requirements of our clients in 80+ countries.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>





        <div class="inner-sec1">
		<div class="container">
			<h3>Our Credentials</h3>
			<p>Acodez was launched in the year 2011. Within this short span of time, we have mastered the craft of web development and digital marketing, ensuring that the client businesses flourish and create a unique brand experience for their dear customers. Our expertise with the latest tools and techniques, and the experience of our professional experts has helped us to provide 100% satisfying results to our customers. We have worked miracles across various sectors of web design and development along with digital marketing, flaunting our creativity and skills, which has bagged us a lot of accolades and awards.

</p>

			<div class="credentials clearfix">
				<div class="col-md-4 col-sm-6 box">
					<div class="outer">
						<div class="icon i-1"></div>
						<p>We are a Google Certified Partner Agency and Bing ads Accredited professional company</p>
					</div>
				</div>
				<div class="col-md-4 col-sm-6 box">
					<div class="outer">
						<div class="icon i-2"></div>
						<p>We use cutting-edge technologies &amp; follow Agile methodology
</p>
					</div>
				</div>
				<div class="col-md-4 col-sm-6 box">
					<div class="outer">
						<div class="icon i-3"></div>
						<p>Multiple International Award winning Agency for the <br/> past 6 years</p>
					</div>
				</div>
				<div class="col-md-4 col-sm-6 box">
					<div class="outer">
						<div class="icon i-4"></div>
						<p>We have a dedicated UX team and follow a proven <br/> UX process 
</p>
					</div>
				</div>
				<div class="col-md-4 col-sm-6 box">
					<div class="outer">
						<div class="icon i-5"></div>
						<p>1000+ successfully completed projects for various clients from 70+ countries 
</p>
					</div>
				</div>
				<div class="col-md-4 col-sm-6 box">
					<div class="outer">
						<div class="icon i-6"></div>
						<p>Listed as Top Web Design Agency in India by various rating websites</p>
					</div>
				</div>
			</div>
		</div>
	</div>
        </section>
        <Footer />
    <Footer2 />
    </div>
  )
}

export default About
