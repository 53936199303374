import React from 'react'
import './Technologies.css'
import Header from '../../header/Header'
import Footer from '../../Footer1/Footer'
import Footer2 from '../../footer2/Footer2'
const Technologies = () => {
  return (
    <div className='technologies'>
    <Header />
    <section class="inner-page">
        <div class="inner-banner work-img">
            <div class="img "></div>
            <img src="https://cdn.acodez.in/wp-content/themes/acodez-theme/images/works-banner.jpg" alt="" />
            <div class="caption">
                <div class="container">
                    <div class="row">
                        <div class="col-md-6 col-md-offset-6">
                            <h2>Technologies we use</h2>
                            <p>Acodez is a leading <a href="">web design company in India</a> offering all kinds of web design and development solutions. We've always been at the forefront of technological advancements. We use cutting-edge technologies, advanced frameworks and proven methodologies to ensure that our solutions are future-ready and scalable.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className='container'>
<h3 class="main-title-h3">Our Expertise</h3>
        
        <div className='technologies-1'>
{/* 1 */}
            <div class="item  websites" >
                <div class="outer">
                <div class="icon "></div>
                  <h5>Content Managed Sites</h5>
                  
                </div>
            </div>
{/* 2 */}
            
            <div class="item  websites" >
                <div class="outer">
                <div class="icon  i-ecommerce"></div>
                  <h5>E-commerce Websites</h5>
                  
                </div>
            </div>
 {/* 3           */}
            <div class="item  websites" >
                <div class="outer">
                <div class="icon i-cms"></div>
                  <h5>Content Managed Sites</h5>
                  
                </div>
            </div>
    {/* 4        */}
            <div class="item  websites" >
                <div class="outer">
                <div class="icon i-php "></div>
                  <h5>PHP Development</h5>
                  
                </div>
            </div>
{/* 5 */}
            <div class="item  websites" >
                <div class="outer">
                <div class="icon i-jquery"></div>
                  <h5>jQuery Websites</h5>
                  
                </div>
            </div>  
{/* 6 */}

            <div class="item  websites" >
                <div class="outer">
                <div class="icon i-android"></div>
                  <h5>Android Application Development</h5>
                  
                </div>
            </div>  
            {/* 7 */}
            <div class="item  websites" >
                <div class="outer">
                <div class="icon i-cakePHP"></div>
                  <h5>CakePHP Development</h5>
                  
                </div>
            </div> 
            {/* 8 */}

            <div class="item  websites" >
                <div class="outer">
                <div class="icon i-drupal"></div>
                  <h5>Drupal Development
</h5>
                  
                </div>
            </div> 

            {/* 9 */}

            <div class="item  websites" >
                <div class="outer">
                <div class="icon i-codeIgniter"></div>
                  <h5>CodeIgniter Development</h5>
                  
                </div>
            </div> 

            {/* 10 */}

            <div class="item  websites" >
                <div class="outer">
                <div class="icon i-dotNet"></div>
                  <h5>DotNetNuke Development</h5>
                  
                </div>
            </div> 

        </div>
        </div>


    </section>
    <Footer />
    <Footer2 />
</div>
  )
}

export default Technologies
