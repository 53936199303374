import React from 'react'
import './WebdeveloperSlider.css'

import { Swiper, SwiperSlide } from 'swiper/react';
import img from '../../img/elegantreport.webp'
import img1 from '../../img/lemo.webp'
import img2 from '../../img/realestate.webp'
import img3 from '../../img/trueparking.webp'


import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';


import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination } from 'swiper/modules';
const WebdeveloperSlider = () => {
  return (
    <section className='webdeveloper-slider'>
      <div className="container">
      
            <div class="webdevslider-content">
                <h2>Web Development</h2>
                				
                <p>We ensure that all our web development and mobile application development projects are not only
                    engineered using the most cutting edge technologies, but also they're created through a
                    human-centred design process.</p>
            </div>

            <div className='webdevslider-tabs'>
            <Tabs
      defaultActiveKey="Web Application"
      id="uncontrolled-tab-example"
      className="mb-3"
    >

<Tab eventKey="Web Application" title="Web Application" >
        
               <Swiper
                  rewind={true}
                    slidesPerView={3}
                    spaceBetween={30}
                  
                    pagination={{
                        clickable: true,
                    }}
                    modules={[Pagination]}
                    className="mySwiper"
                >
                <SwiperSlide>
                <div class="swiper-slide web-item hvr-sweep-to-top swiper-slide-duplicate"  data-swiper-slide-index="7">
                            <a href="javascript:void(0);">
                                <img loading="lazy" src={img} alt=""/>
                            </a>
                            <div class="text-overlay">
                                <div class="outer-overlay">
                                    <p>UX design and development for Remit Money, an Axis Bank Brand. We also developed
                                        the brand Identity design.</p>
                                    <a href="" rel="nofollow" class="btn-1 white-cle" target="_blank">View Work</a>
                                </div>
                            </div>
                        </div>

                </SwiperSlide>
                <SwiperSlide>
                <div class="swiper-slide web-item hvr-sweep-to-top swiper-slide-duplicate" data-swiper-slide-index="7">
                            <a href="javascript:void(0);">
                                <img loading="lazy" src={img1} alt=""/>
                            </a>
                            <div class="text-overlay">
                                <div class="outer-overlay">
                                    <p>UX design and development for Remit Money, an Axis Bank Brand. We also developed
                                        the brand Identity design.</p>
                                    <a href="" rel="nofollow" class="btn-1 white-cle" target="_blank">View Work</a>
                                </div>
                            </div>
                        </div>

                </SwiperSlide><SwiperSlide>
                <div class="swiper-slide web-item hvr-sweep-to-top swiper-slide-duplicate" data-swiper-slide-index="7">
                            <a href="javascript:void(0);">
                                <img loading="lazy" src={img3} alt=""/>
                            </a>
                            <div class="text-overlay">
                                <div class="outer-overlay">
                                    <p>UX design and development for Remit Money, an Axis Bank Brand. We also developed
                                        the brand Identity design.</p>
                                    <a href="" rel="nofollow" class="btn-1 white-cle" target="_blank">View Work</a>
                                </div>
                            </div>
                        </div>

                </SwiperSlide>
                <SwiperSlide>
                <div class="swiper-slide web-item hvr-sweep-to-top swiper-slide-duplicate" data-swiper-slide-index="7">
                            <a href="javascript:void(0);">
                                <img loading="lazy" src={img} alt=""/>
                            </a>
                            <div class="text-overlay">
                                <div class="outer-overlay">
                                    <p>UX design and development for Remit Money, an Axis Bank Brand. We also developed
                                        the brand Identity design.</p>
                                    <a href="" rel="nofollow" class="btn-1 white-cle" target="_blank">View Work</a>
                                </div>
                            </div>
                        </div>

                </SwiperSlide>
                <SwiperSlide>
                <div class="swiper-slide web-item hvr-sweep-to-top swiper-slide-duplicate" data-swiper-slide-index="7">
                            <a href="javascript:void(0);">
                                <img loading="lazy" src={img1} alt=""/>
                            </a>
                            <div class="text-overlay">
                                <div class="outer-overlay">
                                    <p>UX design and development for Remit Money, an Axis Bank Brand. We also developed
                                        the brand Identity design.</p>
                                    <a href="" rel="nofollow" class="btn-1 white-cle" target="_blank">View Work</a>
                                </div>
                            </div>
                        </div>

                </SwiperSlide>
                <SwiperSlide>
                <div class="swiper-slide web-item hvr-sweep-to-top swiper-slide-duplicate" data-swiper-slide-index="7">
                            <a href="javascript:void(0);">
                                <img loading="lazy" src={img2} alt=""/>
                            </a>
                            <div class="text-overlay">
                                <div class="outer-overlay">
                                    <p>UX design and development for Remit Money, an Axis Bank Brand. We also developed
                                        the brand Identity design.</p>
                                    <a href="" rel="nofollow" class="btn-1 white-cle" target="_blank">View Work</a>
                                </div>
                            </div>
                        </div>

                </SwiperSlide>


                <SwiperSlide>
                <div class="swiper-slide web-item hvr-sweep-to-top swiper-slide-duplicate" data-swiper-slide-index="7">
                            <a href="javascript:void(0);">
                                <img loading="lazy" src={img3} alt=""/>
                            </a>
                            <div class="text-overlay">
                                <div class="outer-overlay">
                                    <p>UX design and development for Remit Money, an Axis Bank Brand. We also developed
                                        the brand Identity design.</p>
                                    <a href="" rel="nofollow" class="btn-1 white-cle" target="_blank">View Work</a>
                                </div>
                            </div>
                        </div>

                </SwiperSlide>
                <SwiperSlide>
                <div class="swiper-slide web-item hvr-sweep-to-top swiper-slide-duplicate" data-swiper-slide-index="7">
                            <a href="javascript:void(0);">
                                <img loading="lazy" src={img} alt=""/>
                            </a>
                            <div class="text-overlay">
                                <div class="outer-overlay">
                                    <p>UX design and development for Remit Money, an Axis Bank Brand. We also developed
                                        the brand Identity design.</p>
                                    <a href="" rel="nofollow" class="btn-1 white-cle" target="_blank">View Work</a>
                                </div>
                            </div>
                        </div>

                </SwiperSlide>

                </Swiper>
      </Tab>
      <Tab eventKey="Mobile App" title="Mobile App" >
      <Swiper
                  rewind={true}
                    slidesPerView={5}
                    spaceBetween={30}
                  
                    pagination={{
                        clickable: true,
                    }}
                    modules={[Pagination]}
                    className="mySwiper"
                >
                
                <SwiperSlide>
                <div class="swiper-slide web-item hvr-sweep-to-top swiper-slide-duplicate" data-swiper-slide-index="7">
                            <a href="javascript:void(0);">
                                <img loading="lazy" src={img1} alt=""/>
                            </a>
                            <div class="text-overlay">
                                <div class="outer-overlay">
                                    <p>UX design and development for Remit Money, an Axis Bank Brand. We also developed
                                        the brand Identity design.</p>
                                    <a href="" rel="nofollow" class="btn-1 white-cle" target="_blank">View Work</a>
                                </div>
                            </div>
                        </div>

                </SwiperSlide>
                
                <SwiperSlide>
                <div class="swiper-slide web-item hvr-sweep-to-top swiper-slide-duplicate"  data-swiper-slide-index="7">
                            <a href="javascript:void(0);">
                                <img loading="lazy" src={img} alt=""/>
                            </a>
                            <div class="text-overlay">
                                <div class="outer-overlay">
                                    <p>UX design and development for Remit Money, an Axis Bank Brand. We also developed
                                        the brand Identity design.</p>
                                    <a href="" rel="nofollow" class="btn-1 white-cle" target="_blank">View Work</a>
                                </div>
                            </div>
                        </div>

                </SwiperSlide>
                
                <SwiperSlide>
                <div class="swiper-slide web-item hvr-sweep-to-top swiper-slide-duplicate" data-swiper-slide-index="7">
                            <a href="javascript:void(0);">
                                <img loading="lazy" src={img3} alt=""/>
                            </a>
                            <div class="text-overlay">
                                <div class="outer-overlay">
                                    <p>UX design and development for Remit Money, an Axis Bank Brand. We also developed
                                        the brand Identity design.</p>
                                    <a href="" rel="nofollow" class="btn-1 white-cle" target="_blank">View Work</a>
                                </div>
                            </div>
                        </div>

                </SwiperSlide>
                <SwiperSlide>
                <div class="swiper-slide web-item hvr-sweep-to-top swiper-slide-duplicate" data-swiper-slide-index="7">
                            <a href="javascript:void(0);">
                                <img loading="lazy" src={img} alt=""/>
                            </a>
                            <div class="text-overlay">
                                <div class="outer-overlay">
                                    <p>UX design and development for Remit Money, an Axis Bank Brand. We also developed
                                        the brand Identity design.</p>
                                    <a href="" rel="nofollow" class="btn-1 white-cle" target="_blank">View Work</a>
                                </div>
                            </div>
                        </div>

                </SwiperSlide>
                <SwiperSlide>
                <div class="swiper-slide web-item hvr-sweep-to-top swiper-slide-duplicate" data-swiper-slide-index="7">
                            <a href="javascript:void(0);">
                                <img loading="lazy" src={img1} alt=""/>
                            </a>
                            <div class="text-overlay">
                                <div class="outer-overlay">
                                    <p>UX design and development for Remit Money, an Axis Bank Brand. We also developed
                                        the brand Identity design.</p>
                                    <a href="" rel="nofollow" class="btn-1 white-cle" target="_blank">View Work</a>
                                </div>
                            </div>
                        </div>

                </SwiperSlide>
                <SwiperSlide>
                <div class="swiper-slide web-item hvr-sweep-to-top swiper-slide-duplicate" data-swiper-slide-index="7">
                            <a href="javascript:void(0);">
                                <img loading="lazy" src={img2} alt=""/>
                            </a>
                            <div class="text-overlay">
                                <div class="outer-overlay">
                                    <p>UX design and development for Remit Money, an Axis Bank Brand. We also developed
                                        the brand Identity design.</p>
                                    <a href="" rel="nofollow" class="btn-1 white-cle" target="_blank">View Work</a>
                                </div>
                            </div>
                        </div>

                </SwiperSlide>


                <SwiperSlide>
                <div class="swiper-slide web-item hvr-sweep-to-top swiper-slide-duplicate" data-swiper-slide-index="7">
                            <a href="javascript:void(0);">
                                <img loading="lazy" src={img3} alt=""/>
                            </a>
                            <div class="text-overlay">
                                <div class="outer-overlay">
                                    <p>UX design and development for Remit Money, an Axis Bank Brand. We also developed
                                        the brand Identity design.</p>
                                    <a href="" rel="nofollow" class="btn-1 white-cle" target="_blank">View Work</a>
                                </div>
                            </div>
                        </div>

                </SwiperSlide>
                <SwiperSlide>
                <div class="swiper-slide web-item hvr-sweep-to-top swiper-slide-duplicate" data-swiper-slide-index="7">
                            <a href="javascript:void(0);">
                                <img loading="lazy" src={img} alt=""/>
                            </a>
                            <div class="text-overlay">
                                <div class="outer-overlay">
                                    <p>UX design and development for Remit Money, an Axis Bank Brand. We also developed
                                        the brand Identity design.</p>
                                    <a href="" rel="nofollow" class="btn-1 white-cle" target="_blank">View Work</a>
                                </div>
                            </div>
                        </div>

                </SwiperSlide>

                </Swiper>
      </Tab>
      <Tab eventKey="CMS website" title="CMS Website" >
      <Swiper
                  rewind={true}
                    slidesPerView={5}
                    spaceBetween={30}
                  
                    pagination={{
                        clickable: true,
                    }}
                    modules={[Pagination]}
                    className="mySwiper"
                >
                <SwiperSlide>
                <div class="swiper-slide web-item hvr-sweep-to-top swiper-slide-duplicate"  data-swiper-slide-index="7">
                            <a href="javascript:void(0);">
                                <img loading="lazy" src={img} alt=""/>
                            </a>
                            <div class="text-overlay">
                                <div class="outer-overlay">
                                    <p>UX design and development for Remit Money, an Axis Bank Brand. We also developed
                                        the brand Identity design.</p>
                                    <a href="" rel="nofollow" class="btn-1 white-cle" target="_blank">View Work</a>
                                </div>
                            </div>
                        </div>

                </SwiperSlide>
                
                <SwiperSlide>
                <div class="swiper-slide web-item hvr-sweep-to-top swiper-slide-duplicate" data-swiper-slide-index="7">
                            <a href="javascript:void(0);">
                                <img loading="lazy" src={img3} alt=""/>
                            </a>
                            <div class="text-overlay">
                                <div class="outer-overlay">
                                    <p>UX design and development for Remit Money, an Axis Bank Brand. We also developed
                                        the brand Identity design.</p>
                                    <a href="" rel="nofollow" class="btn-1 white-cle" target="_blank">View Work</a>
                                </div>
                            </div>
                        </div>

                </SwiperSlide>
                <SwiperSlide>
                <div class="swiper-slide web-item hvr-sweep-to-top swiper-slide-duplicate" data-swiper-slide-index="7">
                            <a href="javascript:void(0);">
                                <img loading="lazy" src={img1} alt=""/>
                            </a>
                            <div class="text-overlay">
                                <div class="outer-overlay">
                                    <p>UX design and development for Remit Money, an Axis Bank Brand. We also developed
                                        the brand Identity design.</p>
                                    <a href="" rel="nofollow" class="btn-1 white-cle" target="_blank">View Work</a>
                                </div>
                            </div>
                        </div>

                </SwiperSlide>
                <SwiperSlide>
                <div class="swiper-slide web-item hvr-sweep-to-top swiper-slide-duplicate" data-swiper-slide-index="7">
                            <a href="javascript:void(0);">
                                <img loading="lazy" src={img} alt=""/>
                            </a>
                            <div class="text-overlay">
                                <div class="outer-overlay">
                                    <p>UX design and development for Remit Money, an Axis Bank Brand. We also developed
                                        the brand Identity design.</p>
                                    <a href="" rel="nofollow" class="btn-1 white-cle" target="_blank">View Work</a>
                                </div>
                            </div>
                        </div>

                </SwiperSlide>
                <SwiperSlide>
                <div class="swiper-slide web-item hvr-sweep-to-top swiper-slide-duplicate" data-swiper-slide-index="7">
                            <a href="javascript:void(0);">
                                <img loading="lazy" src={img1} alt=""/>
                            </a>
                            <div class="text-overlay">
                                <div class="outer-overlay">
                                    <p>UX design and development for Remit Money, an Axis Bank Brand. We also developed
                                        the brand Identity design.</p>
                                    <a href="" rel="nofollow" class="btn-1 white-cle" target="_blank">View Work</a>
                                </div>
                            </div>
                        </div>

                </SwiperSlide>
                <SwiperSlide>
                <div class="swiper-slide web-item hvr-sweep-to-top swiper-slide-duplicate" data-swiper-slide-index="7">
                            <a href="javascript:void(0);">
                                <img loading="lazy" src={img2} alt=""/>
                            </a>
                            <div class="text-overlay">
                                <div class="outer-overlay">
                                    <p>UX design and development for Remit Money, an Axis Bank Brand. We also developed
                                        the brand Identity design.</p>
                                    <a href="" rel="nofollow" class="btn-1 white-cle" target="_blank">View Work</a>
                                </div>
                            </div>
                        </div>

                </SwiperSlide>


                <SwiperSlide>
                <div class="swiper-slide web-item hvr-sweep-to-top swiper-slide-duplicate" data-swiper-slide-index="7">
                            <a href="javascript:void(0);">
                                <img loading="lazy" src={img3} alt=""/>
                            </a>
                            <div class="text-overlay">
                                <div class="outer-overlay">
                                    <p>UX design and development for Remit Money, an Axis Bank Brand. We also developed
                                        the brand Identity design.</p>
                                    <a href="" rel="nofollow" class="btn-1 white-cle" target="_blank">View Work</a>
                                </div>
                            </div>
                        </div>

                </SwiperSlide>
                <SwiperSlide>
                <div class="swiper-slide web-item hvr-sweep-to-top swiper-slide-duplicate" data-swiper-slide-index="7">
                            <a href="javascript:void(0);">
                                <img loading="lazy" src={img} alt=""/>
                            </a>
                            <div class="text-overlay">
                                <div class="outer-overlay">
                                    <p>UX design and development for Remit Money, an Axis Bank Brand. We also developed
                                        the brand Identity design.</p>
                                    <a href="" rel="nofollow" class="btn-1 white-cle" target="_blank">View Work</a>
                                </div>
                            </div>
                        </div>

                </SwiperSlide>

                </Swiper>t
      </Tab>
      <Tab eventKey="E Commerce Website" title="E Commerce Website" >
      <Swiper
                  rewind={true}
                    slidesPerView={5}
                    spaceBetween={30}
                  
                    pagination={{
                        clickable: true,
                    }}
                    modules={[Pagination]}
                    className="mySwiper"
                >
                <SwiperSlide>
                <div class="swiper-slide web-item hvr-sweep-to-top swiper-slide-duplicate" data-swiper-slide-index="7">
                            <a href="javascript:void(0);">
                                <img loading="lazy" src={img1} alt=""/>
                            </a>
                            <div class="text-overlay">
                                <div class="outer-overlay">
                                    <p>UX design and development for Remit Money, an Axis Bank Brand. We also developed
                                        the brand Identity design.</p>
                                    <a href="" rel="nofollow" class="btn-1 white-cle" target="_blank">View Work</a>
                                </div>
                            </div>
                        </div>

                </SwiperSlide>
                <SwiperSlide>
                <div class="swiper-slide web-item hvr-sweep-to-top swiper-slide-duplicate"  data-swiper-slide-index="7">
                            <a href="javascript:void(0);">
                                <img loading="lazy" src={img} alt=""/>
                            </a>
                            <div class="text-overlay">
                                <div class="outer-overlay">
                                    <p>UX design and development for Remit Money, an Axis Bank Brand. We also developed
                                        the brand Identity design.</p>
                                    <a href="" rel="nofollow" class="btn-1 white-cle" target="_blank">View Work</a>
                                </div>
                            </div>
                        </div>

                </SwiperSlide>
                <SwiperSlide>
                <div class="swiper-slide web-item hvr-sweep-to-top swiper-slide-duplicate" data-swiper-slide-index="7">
                            <a href="javascript:void(0);">
                                <img loading="lazy" src={img1} alt=""/>
                            </a>
                            <div class="text-overlay">
                                <div class="outer-overlay">
                                    <p>UX design and development for Remit Money, an Axis Bank Brand. We also developed
                                        the brand Identity design.</p>
                                    <a href="" rel="nofollow" class="btn-1 white-cle" target="_blank">View Work</a>
                                </div>
                            </div>
                        </div>

                </SwiperSlide>
                <SwiperSlide>
                <div class="swiper-slide web-item hvr-sweep-to-top swiper-slide-duplicate" data-swiper-slide-index="7">
                            <a href="javascript:void(0);">
                                <img loading="lazy" src={img3} alt=""/>
                            </a>
                            <div class="text-overlay">
                                <div class="outer-overlay">
                                    <p>UX design and development for Remit Money, an Axis Bank Brand. We also developed
                                        the brand Identity design.</p>
                                    <a href="" rel="nofollow" class="btn-1 white-cle" target="_blank">View Work</a>
                                </div>
                            </div>
                        </div>

                </SwiperSlide>
                <SwiperSlide>
                <div class="swiper-slide web-item hvr-sweep-to-top swiper-slide-duplicate" data-swiper-slide-index="7">
                            <a href="javascript:void(0);">
                                <img loading="lazy" src={img} alt=""/>
                            </a>
                            <div class="text-overlay">
                                <div class="outer-overlay">
                                    <p>UX design and development for Remit Money, an Axis Bank Brand. We also developed
                                        the brand Identity design.</p>
                                    <a href="" rel="nofollow" class="btn-1 white-cle" target="_blank">View Work</a>
                                </div>
                            </div>
                        </div>

                </SwiperSlide>
                
                <SwiperSlide>
                <div class="swiper-slide web-item hvr-sweep-to-top swiper-slide-duplicate" data-swiper-slide-index="7">
                            <a href="javascript:void(0);">
                                <img loading="lazy" src={img2} alt=""/>
                            </a>
                            <div class="text-overlay">
                                <div class="outer-overlay">
                                    <p>UX design and development for Remit Money, an Axis Bank Brand. We also developed
                                        the brand Identity design.</p>
                                    <a href="" rel="nofollow" class="btn-1 white-cle" target="_blank">View Work</a>
                                </div>
                            </div>
                        </div>

                </SwiperSlide>


                <SwiperSlide>
                <div class="swiper-slide web-item hvr-sweep-to-top swiper-slide-duplicate" data-swiper-slide-index="7">
                            <a href="javascript:void(0);">
                                <img loading="lazy" src={img3} alt=""/>
                            </a>
                            <div class="text-overlay">
                                <div class="outer-overlay">
                                    <p>UX design and development for Remit Money, an Axis Bank Brand. We also developed
                                        the brand Identity design.</p>
                                    <a href="" rel="nofollow" class="btn-1 white-cle" target="_blank">View Work</a>
                                </div>
                            </div>
                        </div>

                </SwiperSlide>
                <SwiperSlide>
                <div class="swiper-slide web-item hvr-sweep-to-top swiper-slide-duplicate" data-swiper-slide-index="7">
                            <a href="javascript:void(0);">
                                <img loading="lazy" src={img} alt=""/>
                            </a>
                            <div class="text-overlay">
                                <div class="outer-overlay">
                                    <p>UX design and development for Remit Money, an Axis Bank Brand. We also developed
                                        the brand Identity design.</p>
                                    <a href="" rel="nofollow" class="btn-1 white-cle" target="_blank">View Work</a>
                                </div>
                            </div>
                        </div>

                </SwiperSlide>

                </Swiper>
      </Tab>
      </Tabs>
            </div>
            
       
      </div>
    </section>
  )
}

export default WebdeveloperSlider
